@charset "UTF-8";
/* line 1, ../sass/global/_general.sass */
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple LiGothic Medium", "DFLiHeiMedium", "微軟正黑體", "Microsoft JhengHei" !important;
}

/* line 1, ../sass/page/_headerbar.sass */
.MenuBarNav {
  box-shadow: 0px 2px 2px 0px rgba(51, 51, 102, 0.2);
}
/* line 3, ../sass/page/_headerbar.sass */
.MenuBarNav ul {
  margin-bottom: 0px;
}
/* line 5, ../sass/page/_headerbar.sass */
.MenuBarNav ul .NavIcon {
  cursor: pointer;
  padding: 0.5rem 0.6rem;
}
/* line 8, ../sass/page/_headerbar.sass */
.MenuBarNav .MenuBtn {
  padding: 0.25rem 0.25rem;
}
/* line 10, ../sass/page/_headerbar.sass */
.MenuBarNav .navbar-toggler {
  border: none;
  margin: auto 0.25rem;
}
/* line 13, ../sass/page/_headerbar.sass */
.MenuBarNav .navbar-toggler-icon {
  color: #DBC28C;
}

/*特殊寫法嗎暫留 */
/* line 25, ../sass/page/_headerbar.sass */
.nav-item-group {
  position: relative;
}
/* line 27, ../sass/page/_headerbar.sass */
.nav-item-group .redeem {
  position: absolute;
  top: 4px;
  left: -65px;
  text-align: center;
  color: #d94454;
}
/* line 33, ../sass/page/_headerbar.sass */
.nav-item-group .redeem .txt {
  font-size: 0.75rem;
  color: #696969;
}
/* line 36, ../sass/page/_headerbar.sass */
.nav-item-group ul {
  display: flex;
  align-items: center;
}

/* line 41, ../sass/page/_headerbar.sass */
.NavBarText {
  text-align: center;
  margin: auto auto 0px auto;
}
/* line 44, ../sass/page/_headerbar.sass */
.NavBarText .nav-item {
  position: relative;
  margin: 0px;
  padding: 0px 10px;
}
/* line 48, ../sass/page/_headerbar.sass */
.NavBarText .nav-item .NavText {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0px;
  text-align: center;
  line-height: 2rem;
}
/* line 54, ../sass/page/_headerbar.sass */
.NavBarText .nav-item .selectStores, .NavBarText .nav-item .selectMedia, .NavBarText .nav-item .selectProducts, .NavBarText .nav-item .selectSpecials, .NavBarText .nav-item .selectStory, .NavBarText .nav-item .selectHeating-instructions, .NavBarText .nav-item .selectKungfu-noodle, .NavBarText .nav-item .selectProduct-history, .NavBarText .nav-item .selectRedeems, .NavBarText .nav-item .selectDelivery {
  width: 100%;
  height: 2px;
  background-color: #DBC28C;
}

/* line 59, ../sass/page/_headerbar.sass */
.navbar-collapse {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* line 64, ../sass/page/_headerbar.sass */
  .nav-item-group .redeem {
    display: none;
  }

  /* line 67, ../sass/page/_headerbar.sass */
  .NavBarText .nav-item {
    padding: 10px 10px;
    width: 100%;
    border-bottom: 1px #EDEDED solid;
  }
  /* line 71, ../sass/page/_headerbar.sass */
  .NavBarText .nav-item:last-child {
    border-bottom: none;
  }
  /* line 73, ../sass/page/_headerbar.sass */
  .NavBarText .nav-item .NavText {
    line-height: 2rem;
  }
  /* line 75, ../sass/page/_headerbar.sass */
  .NavBarText .nav-item .selectStores, .NavBarText .nav-item .selectMedia, .NavBarText .nav-item .selectProducts, .NavBarText .nav-item .selectSpecials, .NavBarText .nav-item .selectStory, .NavBarText .nav-item .selectHeating-instructions, .NavBarText .nav-item .selectKungfu-noodle, .NavBarText .nav-item .selectProduct-history, .NavBarText .nav-item .selectRedeems, .NavBarText .nav-item .selectDelivery {
    width: 30%;
    margin: 0px auto;
  }
}
@media only screen and (max-width: 576px) {
  /* line 81, ../sass/page/_headerbar.sass */
  .MenuBarNav .navbar-brand {
    width: 120px;
  }
  /* line 83, ../sass/page/_headerbar.sass */
  .MenuBarNav .navbar-brand img {
    width: 100%;
    height: 100%;
  }
}
/* line 1, ../sass/page/_footerbar.sass */
.footerAll {
  padding: 40px 10px 40px 10px;
  background: #EDEDED;
}
/* line 4, ../sass/page/_footerbar.sass */
.footerAll .Text_24 {
  line-height: 20px;
  color: #696969;
  font-size: 1.2rem;
}

/* line 8, ../sass/page/_footerbar.sass */
.footerText {
  margin-top: 5px;
}
/* line 10, ../sass/page/_footerbar.sass */
.footerText a {
  color: #696969;
  text-decoration: none;
}
/* line 14, ../sass/page/_footerbar.sass */
.footerText .footerNav a {
  display: inline-block;
  padding: 10px;
  position: relative;
  font-weight: bold;
}
/* line 19, ../sass/page/_footerbar.sass */
.footerText .footerNav a:hover {
  color: #cf1227;
}
/* line 22, ../sass/page/_footerbar.sass */
.footerText .footerNav a ~ a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  background-color: #9e9e9e;
  width: 2px;
  height: 20px;
  margin-top: -10px;
}
/* line 31, ../sass/page/_footerbar.sass */
.footerText .info {
  padding: 5px 10px;
}
/* line 33, ../sass/page/_footerbar.sass */
.footerText .info > div:last-child {
  margin-left: 30px;
}
@media only screen and (max-width: 768px) {
  /* line 37, ../sass/page/_footerbar.sass */
  .footerText .footerNav a {
    width: 33%;
    text-align: center;
  }
  /* line 40, ../sass/page/_footerbar.sass */
  .footerText .info {
    padding: 15px 30px;
  }
  /* line 42, ../sass/page/_footerbar.sass */
  .footerText .info > div:last-child {
    margin-left: 0px;
    margin-top: 10px;
  }
}

/* line 47, ../sass/page/_footerbar.sass */
.Text_24 {
  line-height: 20px;
  color: #696969;
  font-size: 1.5rem;
}

/* line 52, ../sass/page/_footerbar.sass */
.Text_18 {
  color: #696969;
  font-size: 1rem;
  font-weight: lighter;
  margin-bottom: 10px;
}

/* line 58, ../sass/page/_footerbar.sass */
.dividers {
  background: #D8D8D8;
  margin: 10px;
  width: 60%;
  height: 1px;
}

/* line 64, ../sass/page/_footerbar.sass */
.btn_top {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 30px;
  right: 30px;
}

/* line 71, ../sass/page/_footerbar.sass */
.footerLogo {
  width: 150px;
}

/* line 76, ../sass/page/_footerbar.sass */
.FBLINEBOX {
  position: fixed;
  width: 80px;
  height: 150px;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  background-color: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

/* line 88, ../sass/page/_footerbar.sass */
.iconFB {
  margin: 10px;
  background-image: url("../images/icon_fb_normal.png");
  width: 60px;
  height: 60px;
}
/* line 93, ../sass/page/_footerbar.sass */
.iconFB:hover {
  width: 60px;
  height: 60px;
  background-image: url("../images/icon_fb_press.png");
}

/* line 98, ../sass/page/_footerbar.sass */
.iconLINE {
  margin: 10px;
  background-image: url("../images/icon_line_normal.png");
  width: 60px;
  height: 60px;
}
/* line 103, ../sass/page/_footerbar.sass */
.iconLINE:hover {
  background-image: url("../images/icon_line_press.png");
}

/* line 147, ../sass/page/_footerbar.sass */
#page-shoppingProgress .footerAll .FBLINEBOX, #page-shoppingCompleted .footerAll .FBLINEBOX, #page-products .footerAll .FBLINEBOX, #page-product .footerAll .FBLINEBOX {
  display: none;
}

/* line 150, ../sass/page/_footerbar.sass */
.FBLINEBOX2 {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* line 155, ../sass/page/_footerbar.sass */
  .footerAll .FBLINEBOX {
    display: none;
  }
  /* line 157, ../sass/page/_footerbar.sass */
  .footerAll .FBLINEBOX2 {
    display: block;
  }
}
/* line 1, ../sass/page/_bannerTitle.sass */
.KunfuBannerTitle {
  background: url("../images/kungfuNoodleBanner.png");
}

/* line 3, ../sass/page/_bannerTitle.sass */
.StoresBannerTitle {
  background: url("../images/storesBanner.png");
}

/* line 7, ../sass/page/_bannerTitle.sass */
.StoryBannerTitle {
  background: url("../images/storyBanner.png");
}

/* line 9, ../sass/page/_bannerTitle.sass */
.NewsBannerTitle {
  background: url("../images/newsBanner.png");
}

/* line 11, ../sass/page/_bannerTitle.sass */
.ProductHistoryTitle {
  background: url("../images/productHistoryBanner.png");
}

/* line 13, ../sass/page/_bannerTitle.sass */
.RegistrationBannerTitle {
  background: url("../images/registrationBanner.png");
}

/* line 15, ../sass/page/_bannerTitle.sass */
.MyAccountBannerTitle {
  background: url("../images/myAccountBanner.png");
}

/* line 17, ../sass/page/_bannerTitle.sass */
.ShoppingProgressBannerTitle {
  background: url("../images/shoppingProgressBanner.png");
}

/* line 20, ../sass/page/_bannerTitle.sass */
.KunfuBannerTitle, .StoresBannerTitle, .StoryBannerTitle, .NewsBannerTitle, .ProductHistoryTitle, .RegistrationBannerTitle, .MyAccountBannerTitle, .ShoppingProgressBannerTitle {
  height: 300px;
  background-size: cover;
  background-position: center center;
  text-align: center;
  margin: 80px auto 0 auto;
  background-attachment: fixed;
}
/* line 27, ../sass/page/_bannerTitle.sass */
.KunfuBannerTitle p, .StoresBannerTitle p, .StoryBannerTitle p, .NewsBannerTitle p, .ProductHistoryTitle p, .RegistrationBannerTitle p, .MyAccountBannerTitle p, .ShoppingProgressBannerTitle p {
  color: white;
  font-size: 3em;
  height: 300px;
  line-height: 300px;
  font-weight: lighter;
  letter-spacing: 0.1em;
}

/* line 34, ../sass/page/_bannerTitle.sass */
.ProductBannerTitle {
  height: 80px;
}

@media only screen and (max-width: 768px) {
  /* line 50, ../sass/page/_bannerTitle.sass */
  .KunfuBannerTitle, .StoresBannerTitle, .ProductBannerTitle, .StoryBannerTitle, .NewsBannerTitle, .ProductHistoryTitle, .RegistrationBannerTitle, .MyAccountBannerTitle, .ShoppingProgressBannerTitle {
    height: 0px;
  }
}
/* line 1, ../sass/page/_table.sass */
.TableList {
  border: solid #DBC28C 1px;
}
/* line 4, ../sass/page/_table.sass */
.TableList thead th {
  padding: 15px 0px;
  background-color: #DBC28C;
  text-align: center;
  color: white;
  font-size: 1.1rem;
  line-height: 1.1rem;
  border: none;
}
/* line 12, ../sass/page/_table.sass */
.TableList tr {
  margin: 0px;
  padding: 15px 0px;
  text-align: center;
}
/* line 17, ../sass/page/_table.sass */
.TableList tr:nth-child(even) {
  background-color: #F7F7F7;
}
/* line 19, ../sass/page/_table.sass */
.TableList td {
  border: none;
  text-align: center;
  color: #858585;
}

@media only screen and (max-width: 768px) {
  /* line 25, ../sass/page/_table.sass */
  .TableList {
    margin-top: 25px;
  }
  /* line 27, ../sass/page/_table.sass */
  .TableList table, .TableList thead, .TableList tbody, .TableList th, .TableList td, .TableList tr {
    display: block;
  }
  /* line 29, ../sass/page/_table.sass */
  .TableList thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  /* line 33, ../sass/page/_table.sass */
  .TableList tr {
    padding: 0px;
    border-bottom: 1px solid #DBC28C;
  }
  /* line 36, ../sass/page/_table.sass */
  .TableList td {
    border: none;
    position: relative;
    padding-left: 35%;
  }
  /* line 40, ../sass/page/_table.sass */
  .TableList td:nth-child(even) {
    background-color: #F7F7F7;
  }
  /* line 42, ../sass/page/_table.sass */
  .TableList td:before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30%;
    height: 48px;
    padding-right: 5px;
    white-space: nowrap;
    display: block;
    color: white;
    font-size: 1rem;
    line-height: 48px;
    background-color: #DBC28C;
    vertical-align: middle;
  }
  /* line 56, ../sass/page/_table.sass */
  .TableList td.shoppingComplete:before {
    height: 29px;
    line-height: 2.3;
  }
  /* line 59, ../sass/page/_table.sass */
  .TableList .OrdersTH:nth-of-type(1):before {
    content: "訂單編號";
  }
  /* line 61, ../sass/page/_table.sass */
  .TableList .OrdersTH:nth-of-type(2):before {
    content: "購買明細";
  }
  /* line 63, ../sass/page/_table.sass */
  .TableList .OrdersTH:nth-of-type(3):before {
    content: "結帳時間";
  }
  /* line 65, ../sass/page/_table.sass */
  .TableList .OrdersTH:nth-of-type(4):before {
    content: "訂單狀態";
  }
  /* line 68, ../sass/page/_table.sass */
  .TableList .OrderTH:nth-of-type(1):before {
    content: "商品名稱";
  }
  /* line 70, ../sass/page/_table.sass */
  .TableList .OrderTH:nth-of-type(2):before {
    content: "單價";
  }
  /* line 72, ../sass/page/_table.sass */
  .TableList .OrderTH:nth-of-type(3):before {
    content: "數量";
  }
  /* line 74, ../sass/page/_table.sass */
  .TableList .OrderTH:nth-of-type(4):before {
    content: "商品總額";
  }
  /* line 77, ../sass/page/_table.sass */
  .TableList .StoresTH:nth-of-type(1):before {
    content: "分店位置";
  }
  /* line 79, ../sass/page/_table.sass */
  .TableList .StoresTH:nth-of-type(2):before {
    content: "電話";
  }
  /* line 81, ../sass/page/_table.sass */
  .TableList .StoresTH:nth-of-type(3):before {
    content: "營業時間";
  }
  /* line 83, ../sass/page/_table.sass */
  .TableList .StoresTH:nth-of-type(4):before {
    content: "地址";
  }
  /* line 86, ../sass/page/_table.sass */
  .TableList .CounponTH:nth-of-type(1):before {
    content: "訂單編號";
  }
  /* line 88, ../sass/page/_table.sass */
  .TableList .CounponTH:nth-of-type(2):before {
    content: "面額";
  }
  /* line 90, ../sass/page/_table.sass */
  .TableList .CounponTH:nth-of-type(3):before {
    content: "抵用門檻";
  }
  /* line 92, ../sass/page/_table.sass */
  .TableList .CounponTH:nth-of-type(4):before {
    content: "使用狀況";
  }
  /* line 94, ../sass/page/_table.sass */
  .TableList .CounponTH:nth-of-type(5):before {
    content: "有效期限";
  }
}
@media only screen and (max-width: 576px) {
  /* line 99, ../sass/page/_table.sass */
  .TableList td {
    padding-left: 30%;
    font-size: 0.8rem;
  }
  /* line 102, ../sass/page/_table.sass */
  .TableList td img {
    width: 15px;
    height: 15px;
  }
  /* line 105, ../sass/page/_table.sass */
  .TableList td:before {
    width: 25%;
    height: 65px;
    font-size: 0.8rem;
  }

  /* line 110, ../sass/page/_table.sass */
  .OrderContent td.OrderTH {
    padding: 0;
    line-height: 29px;
  }
}
@media only screen and (max-width: 1024px) {
  /* line 116, ../sass/page/_table.sass */
  .rwd-table td.rwd-table-hide {
    display: none;
  }
  /* line 118, ../sass/page/_table.sass */
  .rwd-table td.td-count {
    margin: -40px 0;
    padding: 0;
  }
}
/* line 2, ../sass/page/_messageBox.sass */
.ant-modal-content {
  width: 400px;
  height: 200px;
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* line 11, ../sass/page/_messageBox.sass */
.ant-modal-body {
  padding: 40px 40px !important;
}

/* line 14, ../sass/page/_messageBox.sass */
.ant-confirm-title {
  color: #696969 !important;
  font-size: 1.2rem !important;
  font-weight: lighter !important;
  line-height: 40px !important;
  margin-top: 0px !important;
}

/* line 21, ../sass/page/_messageBox.sass */
.ant-confirm-content {
  color: #696969 !important;
  font-size: 1.2rem !important;
  font-weight: lighter !important;
  margin-left: 0px !important;
  line-height: 40px !important;
  margin-top: 0px !important;
}

/* line 29, ../sass/page/_messageBox.sass */
.anticon, .anticon-question-circle {
  display: none;
}

/* line 32, ../sass/page/_messageBox.sass */
.ant-confirm-btns {
  margin-top: 10px !important;
  float: right;
}

/* line 36, ../sass/page/_messageBox.sass */
.ant-btn-lg {
  width: 140px;
  height: 40px;
  margin: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
  background: #ffffff;
  color: #DBC28C;
  font-weight: lighter;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
/* line 50, ../sass/page/_messageBox.sass */
.ant-btn-lg:hover {
  background-color: #DBC28C !important;
  color: white !important;
  border: 0px #9A7854 solid !important;
}
/* line 54, ../sass/page/_messageBox.sass */
.ant-btn-lg:active {
  background-color: #9A7854 !important;
  color: white !important;
  border: 0px #9A7854 solid !important;
}

/* line 62, ../sass/page/_messageBox.sass */
.ant-confirm-btns button span {
  font-size: 1rem;
  padding: 20px !important;
}

/* line 66, ../sass/page/_messageBox.sass */
.ant-btn-primary {
  border-width: 0px;
  color: #ffffff;
  font-weight: lighter;
  background-color: #DBC28C !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
/* line 75, ../sass/page/_messageBox.sass */
.ant-btn-primary:hover {
  color: #DBC28C !important;
  border: 1px #DBC28C solid !important;
  background-color: white !important;
}
/* line 79, ../sass/page/_messageBox.sass */
.ant-btn-primary:active {
  color: #9A7854 !important;
  border: 1px #9A7854 solid !important;
  background-color: white !important;
}

/* line 2, ../sass/page/_mainpage.sass */
.mainBanner {
  background-image: url("../images/mainBanner_250217.webp");
  background-size: cover;
  background-position: center;
  min-height: 130vh;
  margin-top: 88px;
}

/* line 11, ../sass/page/_mainpage.sass */
.logoBanner {
  position: absolute;
  top: 40vh;
  width: 260px;
  right: 0;
  left: 0;
  margin: auto;
}

/* line 19, ../sass/page/_mainpage.sass */
.btnDown {
  position: absolute;
  top: 92vh;
  right: 0;
  left: 0;
  margin: auto;
  width: 50px;
  height: 50px;
}

/* line 28, ../sass/page/_mainpage.sass */
#page-redeems {
  background-image: url("../images/redeemsBanner_1028.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* line 35, ../sass/page/_mainpage.sass */
.AboutBg {
  background-image: url("../images/bg_beige.png");
  background-size: cover;
  background-position: center center;
  padding: 60px 0px 40px 0px;
}

/* line 41, ../sass/page/_mainpage.sass */
.aboutTitle {
  position: relative;
  text-align: center;
  padding: 0px 0px 30px 0px;
  color: #696969;
  font-size: 2.25rem;
}

/* line 48, ../sass/page/_mainpage.sass */
.aboutImg {
  position: relative;
  height: auto;
  width: 100%;
  padding: 0px 0px 30px 0px;
}

/* line 54, ../sass/page/_mainpage.sass */
.aboutText {
  line-height: 60px;
  color: #696969;
  font-size: 1.25rem;
  font-weight: lighter;
  padding: 20px 30px 30px 20px;
}

/* line 61, ../sass/page/_mainpage.sass */
.aboutBtn {
  padding: 10px 40px;
  color: white;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background-color: #DBC28C;
  cursor: pointer;
}
/* line 69, ../sass/page/_mainpage.sass */
.aboutBtn:hover {
  color: #DBC28C;
  border: 1px #DBC28C solid;
  background-color: white;
}
/* line 73, ../sass/page/_mainpage.sass */
.aboutBtn:active {
  color: #9A7854;
  border: 1px #9A7854 solid;
  background-color: white;
}

@media only screen and (max-width: 800px) {
  /* line 79, ../sass/page/_mainpage.sass */
  .aboutImg {
    width: 50%;
    padding: 0px 0px 30px 0px;
  }

  /* line 82, ../sass/page/_mainpage.sass */
  .aboutText {
    line-height: 60px;
    font-size: 1.1rem;
    padding: 0px 20px 20px 20px;
  }

  /* line 86, ../sass/page/_mainpage.sass */
  .AboutBg {
    padding: 40px 0px 40px 0px;
  }
}
/* line 89, ../sass/page/_mainpage.sass */
.mainPage_Title {
  padding: 0px 0px 40px 0px;
}

/* line 94, ../sass/page/_mainpage.sass */
.blockGole {
  width: 90%;
  height: 2px;
  background-color: #DBC28C;
  margin: 0 auto;
}

/* line 108, ../sass/page/_mainpage.sass */
.Featured {
  background-image: url("../images/bg_beige.png");
  background-size: cover;
  background-position: center center;
  padding: 30px 0px 50px 0px;
}

/* line 114, ../sass/page/_mainpage.sass */
.FeaturedBtnBox {
  position: relative;
  top: 300px;
  width: 100%;
  height: 50px;
}

/* line 120, ../sass/page/_mainpage.sass */
.FeaturedBtnRight {
  position: absolute;
  z-index: 999;
  right: 5vw;
  width: 30px;
  height: 30px;
  display: block;
}

/* line 128, ../sass/page/_mainpage.sass */
.FeaturedBtnLeft {
  position: absolute;
  z-index: 999;
  left: 5vw;
  width: 30px;
  height: 30px;
  display: block;
}

/* line 136, ../sass/page/_mainpage.sass */
.FeaturedImgBox {
  padding: 10px;
}

/* line 139, ../sass/page/_mainpage.sass */
.FeaturedImg {
  width: 100%;
  height: auto;
  border-radius: 50% !important;
  border: 10px solid #C3A88B;
  box-shadow: 0px 5px 15px 0px rgba(51, 51, 51, 0.5);
}

/* line 146, ../sass/page/_mainpage.sass */
.FeaturedText {
  line-height: 50px;
  color: #696969;
  font-size: 1.25rem;
  text-align: center;
  font-weight: lighter;
}

/* line 155, ../sass/page/_mainpage.sass */
.MenuBlock {
  background-color: #C5A97B;
  padding: 60px 5% 60px 5%;
}

/* line 160, ../sass/page/_mainpage.sass */
.mediaCoverage {
  padding: 40px 0px 40px 0px;
  background-color: #ffffff;
}

/* line 167, ../sass/page/_mainpage.sass */
.imgBox {
  width: 100%;
  height: auto;
}

/* line 171, ../sass/page/_mainpage.sass */
.rowBox {
  padding: 0px 30px 0px 30px;
}

/* line 174, ../sass/page/_mainpage.sass */
.colBox {
  padding: 20px;
}

/* line 178, ../sass/page/_mainpage.sass */
.FBBOX {
  padding: 40px 10px 40px 10px;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-image: url("../images/mainFansDivider.png");
}

/* line 185, ../sass/page/_mainpage.sass */
.FBText_1 {
  position: relative;
  top: 100%;
  transform: translateY(-100%);
  color: #ffffff;
  font-size: 2.25rem;
  text-align: center;
  margin-bottom: 1rem;
}

/* line 194, ../sass/page/_mainpage.sass */
.FBText_2 {
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 800px) {
  /* line 201, ../sass/page/_mainpage.sass */
  .FBText_1 {
    font-size: 1.4rem;
  }

  /* line 203, ../sass/page/_mainpage.sass */
  .FBText_2 {
    font-size: 1rem;
    line-height: 50px;
  }
}
/* line 207, ../sass/page/_mainpage.sass */
.fb-page {
  position: relative;
  transform: translateX(0%);
}

/* line 211, ../sass/page/_mainpage.sass */
.use-flexbox {
  height: 100%;
}

@media only screen and (max-width: 576px) {
  /* line 220, ../sass/page/_mainpage.sass */
  .mainBanner {
    background-image: url("../images/mainBanner_02_250217.webp");
    background-size: co;
    background-position: center;
    min-height: 89vh;
    margin-top: 60px;
  }
}
/* line 229, ../sass/page/_mainpage.sass */
.item__content {
  font-size: 24px;
}

@media only screen and (max-width: 800px) {
  /* line 233, ../sass/page/_mainpage.sass */
  .item__content {
    height: 60px;
    font-size: 16px;
  }

  /* line 236, ../sass/page/_mainpage.sass */
  .item {
    padding: 0px;
  }
}
/* line 240, ../sass/page/_mainpage.sass */
.highlights {
  max-width: 960px;
  margin: 0 auto;
}
/* line 243, ../sass/page/_mainpage.sass */
.highlights a {
  display: inline-block;
  margin-bottom: 1.5rem;
}
/* line 246, ../sass/page/_mainpage.sass */
.highlights h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
}
/* line 250, ../sass/page/_mainpage.sass */
.highlights .img {
  width: auto;
  max-width: 100%;
  padding: 0 200px;
  margin-bottom: 2rem;
}
/* line 255, ../sass/page/_mainpage.sass */
.highlights .img.first {
  padding: 0;
}
/* line 257, ../sass/page/_mainpage.sass */
.highlights .img img {
  width: 100%;
  vertical-align: top;
}
/* line 260, ../sass/page/_mainpage.sass */
.highlights .img .imgTxt {
  font-size: 0.875rem;
  color: #ababab;
  font-weight: 300;
}
/* line 264, ../sass/page/_mainpage.sass */
.highlights p {
  margin-bottom: 2rem;
}
/* line 266, ../sass/page/_mainpage.sass */
.highlights blockquote {
  position: relative;
  margin: 40px auto;
}
/* line 269, ../sass/page/_mainpage.sass */
.highlights blockquote p {
  line-height: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 30px 180px;
  margin: 0;
}
/* line 275, ../sass/page/_mainpage.sass */
.highlights blockquote::before, .highlights blockquote::after {
  position: absolute;
  left: 50%;
  width: 60px;
  height: 2px;
  margin-left: -30px;
  content: "";
  display: block;
  background-color: #d60c18;
}

@media only screen and (max-width: 960px) {
  /* line 288, ../sass/page/_mainpage.sass */
  .highlights .img {
    width: auto;
    padding: 0;
  }
}
@media only screen and (max-width: 576px) {
  /* line 293, ../sass/page/_mainpage.sass */
  .highlights {
    width: 90%;
  }
  /* line 295, ../sass/page/_mainpage.sass */
  .highlights h2 {
    font-size: 1.25rem;
  }
  /* line 297, ../sass/page/_mainpage.sass */
  .highlights h3 {
    font-size: 1rem;
  }
  /* line 299, ../sass/page/_mainpage.sass */
  .highlights p {
    font-size: 0.875rem;
    font-weight: normal;
  }
  /* line 303, ../sass/page/_mainpage.sass */
  .highlights blockquote p {
    padding: 30px;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
@media only screen and (max-width: 768px) {
  /* line 308, ../sass/page/_mainpage.sass */
  #page-redeems {
    background-image: none;
  }
}
/* line 6, ../sass/page/_news.sass */
.padding0 {
  padding: 0px;
}

/* line 9, ../sass/page/_news.sass */
.TitleBox {
  width: 90%;
  margin: 120px auto 40px auto;
  padding-bottom: 15px;
  border-bottom: solid #C5C5C5 1px;
}
/* line 14, ../sass/page/_news.sass */
.TitleBox .Title {
  font-size: 1.15rem;
  color: #494949;
  font-weight: 200;
  margin-top: 20px;
}
/* line 19, ../sass/page/_news.sass */
.TitleBox .DirectionTitle {
  color: #C5C5C5;
  padding-top: 20px;
  font-weight: lighter;
}
/* line 23, ../sass/page/_news.sass */
.TitleBox .titleActive {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  color: #DBC28C;
  position: relative;
}
/* line 29, ../sass/page/_news.sass */
.TitleBox .titleActive::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  padding-top: 3px;
  background-color: #DBC28C;
}
/* line 37, ../sass/page/_news.sass */
.TitleBox a.title {
  color: #494949;
  text-decoration: none;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
/* line 43, ../sass/page/_news.sass */
.TitleBox a.title:hover {
  color: #DBC28C;
}

/* line 48, ../sass/page/_news.sass */
.SideBox {
  padding-left: 5%;
  margin-bottom: 30px;
}
/* line 51, ../sass/page/_news.sass */
.SideBox p {
  margin: 0;
  padding: 15px 1% 15px 8.7%;
  font-size: 1.2rem;
  color: #696969;
  border-bottom: dotted #C5C5C5 1px;
  letter-spacing: 0.1rem;
}
/* line 58, ../sass/page/_news.sass */
.SideBox p:hover {
  background-color: #F7F7F7;
  cursor: pointer;
}
/* line 61, ../sass/page/_news.sass */
.SideBox .active {
  background-color: #DBC28C;
  color: white;
}
/* line 64, ../sass/page/_news.sass */
.SideBox .MediaSubP {
  padding: 10px 1% 10px 18%;
  font-size: 1rem;
  color: #696969;
}
/* line 68, ../sass/page/_news.sass */
.SideBox .SideBoxSpecials {
  display: inline-block;
  width: 100%;
  padding: 15px 1% 15px 8.7%;
  font-size: 1.2rem;
  color: #696969;
  border-bottom: dotted #C5C5C5 1px;
}
/* line 75, ../sass/page/_news.sass */
.SideBox .SideBoxSpecials:hover {
  text-decoration: none;
  background-color: #F7F7F7;
}

/* line 78, ../sass/page/_news.sass */
.categorySelect {
  width: 90%;
  margin: auto auto 30px auto;
  display: block;
}
/* line 82, ../sass/page/_news.sass */
.categorySelect select {
  width: 100%;
  margin-bottom: 20px;
  border: solid #C5C5C5 1px;
  border-radius: 0px;
}

/* line 88, ../sass/page/_news.sass */
#categorySelect {
  background: url(../images/select_arrow.svg) right no-repeat white;
  appearance: none;
  padding: 5px 0px 5px 15px;
}

/* line 94, ../sass/page/_news.sass */
.ListContent {
  margin: 0 auto 5% auto;
  padding: 0 20px 0 20px;
  text-align: center;
}
/* line 98, ../sass/page/_news.sass */
.ListContent .List {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
  padding: 12px;
}
/* line 105, ../sass/page/_news.sass */
.ListContent .List a {
  text-decoration: none;
}
/* line 107, ../sass/page/_news.sass */
.ListContent .List .BulletinBoardImgBox {
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}
/* line 113, ../sass/page/_news.sass */
.ListContent .List .BulletinBoardImgBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: solid #DBC28C 1px;
}
/* line 118, ../sass/page/_news.sass */
.ListContent .List .NewsImgBox {
  width: 100%;
  height: 600px;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #DBC28C 1px;
  background-color: #EDEDED;
}
/* line 127, ../sass/page/_news.sass */
.ListContent .List .NewsImgBox img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
/* line 132, ../sass/page/_news.sass */
.ListContent .List .NewsVideoBox {
  height: 280px;
  max-width: 100%;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* line 139, ../sass/page/_news.sass */
.ListContent .List video {
  width: 100%;
  height: 280px;
  border: solid #DBC28C 1px;
  background-color: black;
}
/* line 144, ../sass/page/_news.sass */
.ListContent .List p {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #696969;
}
/* line 149, ../sass/page/_news.sass */
.ListContent .List .date {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #C5C5C5;
}

/* line 154, ../sass/page/_news.sass */
.NewsPaper-modal-dialog {
  margin-top: 20px;
  max-width: 650px;
}

@media only screen and (max-width: 1024px) {
  /* line 161, ../sass/page/_news.sass */
  .ListContent .List .NewsImgBox {
    height: 450px;
  }

  /* line 163, ../sass/page/_news.sass */
  .TitleBox {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 768px) {
  /* line 169, ../sass/page/_news.sass */
  .TitleBox a.title:hover {
    color: #494949;
  }
}
/* line 1, ../sass/page/_productsPage.sass */
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
/* line 5, ../sass/page/_productsPage.sass */
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* line 11, ../sass/page/_productsPage.sass */
.ListContent .ProductList {
  background-color: #ffffff;
}
/* line 13, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox {
  position: relative;
}
/* line 15, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox .Triangle {
  position: absolute;
  border-color: #DBC28C transparent transparent transparent;
  border-style: solid;
  border-width: 60px 60px 0px 0px;
  height: 0px;
  width: 0px;
  z-index: 99;
}
/* line 23, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox .Triangle .Label {
  position: absolute;
  top: -48px;
  left: 2px;
  color: white;
  transform: rotate(-45deg);
}
/* line 29, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox .ImgBox {
  height: 280px;
  max-width: 100%;
  margin: 0 auto 20px auto;
  padding: 5px;
  border: solid #DBC28C 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #DBC28C 1px;
  position: relative;
}
/* line 40, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox .ImgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* line 44, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox .ImgBox .soldout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
/* line 51, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox .ImgBox .soldout:after {
  content: "即將開賣";
  display: block;
  width: 120px;
  height: 120px;
  background-color: #DBC28C;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 73px;
  left: calc(50% - 60px);
  vertical-align: middle;
  padding-top: 41px;
  font-size: 28px;
}
/* line 65, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox .ShippingDate {
  position: absolute;
  top: 247px;
  right: 90px;
  padding: 3px 10px;
  font-size: 0.8rem;
  color: white;
  font-weight: 300;
  background-color: red;
  border-radius: 20px;
}
/* line 75, ../sass/page/_productsPage.sass */
.ListContent .ProductList .ImgGridBox .Quantity {
  position: absolute;
  top: 247px;
  right: 10px;
  padding: 3px 10px;
  font-size: 0.8rem;
  color: white;
  font-weight: 300;
  background-color: #DBC28C;
  border-radius: 20px;
}

/* line 86, ../sass/page/_productsPage.sass */
.ProductsName {
  margin-bottom: 5px;
  font-size: 1rem;
  text-align: left;
  min-height: 48px;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* line 98, ../sass/page/_productsPage.sass */
.ProductsPrice {
  font-size: 1.2rem;
  color: red;
  text-align: left;
  line-height: 2.75rem;
}

/* line 103, ../sass/page/_productsPage.sass */
.ShoppingCart-wrap {
  padding-left: 0;
}

/* line 105, ../sass/page/_productsPage.sass */
.ShoppingCart {
  border: none;
  cursor: pointer;
  text-align: right;
}

/* line 1, ../sass/page/_specials.sass */
.SpecialsContent {
  min-height: 400px;
  width: 90%;
}
/* line 5, ../sass/page/_specials.sass */
.SpecialsContent .specialsBox {
  padding: 0 30px;
  padding-bottom: 200px;
  border-bottom: 1px solid #696969;
}
/* line 9, ../sass/page/_specials.sass */
.SpecialsContent .specialsBox .SpecialsImg {
  width: 100%;
  background-color: #ccc;
  min-height: 300px;
}
/* line 13, ../sass/page/_specials.sass */
.SpecialsContent .specialsName {
  font-size: 1.75rem;
}
/* line 16, ../sass/page/_specials.sass */
.SpecialsContent .specialsSelect {
  background: url(../images/select_arrow.svg) right no-repeat white;
  appearance: none;
  padding: 8px 0px 8px 15px;
  color: #696969;
  font-size: 1rem;
  width: 100%;
}
/* line 24, ../sass/page/_specials.sass */
.SpecialsContent .specialsSelect option:disabled {
  background-color: rgba(170, 170, 170, 0.5);
}
/* line 28, ../sass/page/_specials.sass */
.SpecialsContent .specialsNumberBox input {
  border-radius: 0px;
}
/* line 30, ../sass/page/_specials.sass */
.SpecialsContent .specialsNumberBox input::-webkit-outer-spin-button,
.SpecialsContent .specialsNumberBox input::-webkit-inner-spin-button {
  appearance: none !important;
  margin: 0;
}
/* line 35, ../sass/page/_specials.sass */
.SpecialsContent .specialsNumber {
  width: calc( 100% - 80px );
  margin: 0;
  border-radius: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}
/* line 43, ../sass/page/_specials.sass */
.SpecialsContent .specialsSet {
  border-top: 1px solid #DBC28C;
  padding-top: 15px;
}

/* line 66, ../sass/page/_specials.sass */
.CheckAddText {
  line-height: 46px;
}

/* line 68, ../sass/page/_specials.sass */
.CheckAddBtn {
  background-color: transparent;
  border: 1px solid #cf1126;
  color: #cf1126;
  padding: 10px 15px;
  cursor: pointer;
}
/* line 74, ../sass/page/_specials.sass */
.CheckAddBtn:hover {
  background-color: #cf1126;
  color: #ffffff;
}

/* line 77, ../sass/page/_specials.sass */
.CheckSpecialItemDelete {
  margin: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #DBC28C;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1rem;
  cursor: pointer;
  background-color: white;
}
/* line 88, ../sass/page/_specials.sass */
.CheckSpecialItemDelete:hover {
  background-color: #DBC28C;
  color: white;
}
/* line 91, ../sass/page/_specials.sass */
.CheckSpecialItemDelete:active {
  background-color: #9A7854;
  color: white;
}

@media only screen and (max-width: 576px) {
  /* line 100, ../sass/page/_specials.sass */
  .SpecialsContent .specialsBox {
    padding: 0px;
    padding-bottom: 150px;
  }
  /* line 103, ../sass/page/_specials.sass */
  .SpecialsContent .specialsBox .SpecialsImg {
    min-height: auto;
  }
  /* line 105, ../sass/page/_specials.sass */
  .SpecialsContent .specialsNumber {
    padding-left: 0;
    padding-right: 0;
    min-width: 40px;
  }
  /* line 111, ../sass/page/_specials.sass */
  .SpecialsContent .specialsCombo div div {
    padding-left: 0px;
  }
  /* line 114, ../sass/page/_specials.sass */
  .SpecialsContent .specialsSet .CheckCartBtn {
    margin-top: 15px;
  }
}
/* line 3, ../sass/page/_productHistory.sass */
.ListContent .ProductHistoryList .ImgBox {
  height: 200px;
  max-width: 100%;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #DBC28C 1px;
}
/* line 11, ../sass/page/_productHistory.sass */
.ListContent .ProductHistoryList .ImgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* line 16, ../sass/page/_productHistory.sass */
.SliderBoxCloseBtn {
  position: absolute;
  right: -20px;
  top: -20px;
  z-index: 100;
  opacity: 1;
}
/* line 22, ../sass/page/_productHistory.sass */
.SliderBoxCloseBtn:hover {
  opacity: 1;
}

/* line 26, ../sass/page/_productHistory.sass */
.ProductSliderBox ol {
  margin-bottom: 0px;
}
/* line 28, ../sass/page/_productHistory.sass */
.ProductSliderBox .carousel-indicators {
  margin-left: 0px;
  margin-right: 0px;
  padding: 10px;
  bottom: 0px;
  background-color: #DBC28C;
}
/* line 34, ../sass/page/_productHistory.sass */
.ProductSliderBox .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

/* line 1, ../sass/page/_story.sass */
.StoryContent {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 120px;
}
/* line 5, ../sass/page/_story.sass */
.StoryContent h1 {
  margin-bottom: 30px;
  font-size: 1.6rem;
  color: #494949;
  text-align: center;
  line-height: 2.5rem;
}
/* line 11, ../sass/page/_story.sass */
.StoryContent h2 {
  margin-bottom: 30px;
  font-size: 1.4rem;
  color: #494949;
  font-weight: 400;
  line-height: 2.5rem;
}
/* line 17, ../sass/page/_story.sass */
.StoryContent h3 {
  margin-bottom: 30px;
  font-size: 1.4rem;
  color: #494949;
  text-align: right;
  line-height: 2.8rem;
}
/* line 23, ../sass/page/_story.sass */
.StoryContent p {
  font-size: 1rem;
  color: #858585;
  line-height: 1.85rem;
  text-align: justify;
}
/* line 28, ../sass/page/_story.sass */
.StoryContent .AwardText {
  bottom: 10px;
  padding-bottom: 3%;
  color: white;
  font-size: 1.6rem;
  font-weight: lighter;
  text-align: center;
}
/* line 35, ../sass/page/_story.sass */
.StoryContent .AwardText p {
  color: white;
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: lighter;
  text-align: center;
}
/* line 41, ../sass/page/_story.sass */
.StoryContent .SliderBoxheight {
  border: 1px solid #DBC28C;
  width: 1000px;
  height: 750px;
  background-color: black;
}
/* line 46, ../sass/page/_story.sass */
.StoryContent .SliderBoxheight img {
  width: 100%;
  height: 750px;
  object-fit: contain;
  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  /* line 54, ../sass/page/_story.sass */
  .StoryContent .AwardText p {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  /* line 57, ../sass/page/_story.sass */
  .StoryContent .SliderBoxheight {
    width: 600px;
    height: 450px;
  }
  /* line 60, ../sass/page/_story.sass */
  .StoryContent .SliderBoxheight img {
    height: 450px;
  }
}
@media only screen and (max-width: 576px) {
  /* line 63, ../sass/page/_story.sass */
  .StoryContent {
    width: 90%;
  }
  /* line 65, ../sass/page/_story.sass */
  .StoryContent h1 {
    font-size: 1.2rem;
    text-align-last: left;
  }
  /* line 68, ../sass/page/_story.sass */
  .StoryContent .AwardText {
    padding-bottom: 0px;
  }
  /* line 70, ../sass/page/_story.sass */
  .StoryContent .AwardText p {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  /* line 73, ../sass/page/_story.sass */
  .StoryContent .SliderBoxheight {
    width: 320px;
    height: 240px;
  }
  /* line 76, ../sass/page/_story.sass */
  .StoryContent .SliderBoxheight img {
    height: 240px;
  }
}
/* line 1, ../sass/page/_kungfu-noodle.sass */
.KunfuContent {
  width: 90%;
  margin: 80px auto;
}
/* line 4, ../sass/page/_kungfu-noodle.sass */
.KunfuContent h1 {
  margin-bottom: 30px;
  font-size: 1.6rem;
  color: #494949;
}
/* line 8, ../sass/page/_kungfu-noodle.sass */
.KunfuContent p {
  margin-top: 20px;
  font-size: 1rem;
  color: #858585;
  font-weight: lighter;
  line-height: 2.5rem;
  text-align: justify;
}

/* line 16, ../sass/page/_kungfu-noodle.sass */
.DecorativeLine {
  text-align: center;
}

/* line 18, ../sass/page/_kungfu-noodle.sass */
.TransformY {
  transform: rotateY(180deg);
}

/* line 21, ../sass/page/_kungfu-noodle.sass */
.StoresMap {
  margin: 10px auto 0px auto;
  text-align: center;
}
/* line 24, ../sass/page/_kungfu-noodle.sass */
.StoresMap .MapBox {
  width: 90%;
  padding: 2%;
  text-align: center;
}

/* line 29, ../sass/page/_kungfu-noodle.sass */
.StoreContent {
  margin: 20px 0px 0px 8%;
  text-align: left;
}
/* line 32, ../sass/page/_kungfu-noodle.sass */
.StoreContent p {
  font-size: 1.2rem;
  line-height: 2.5rem;
  color: #858585;
  margin-bottom: 0;
}
/* line 37, ../sass/page/_kungfu-noodle.sass */
.StoreContent a {
  font-size: 1.2rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}
/* line 41, ../sass/page/_kungfu-noodle.sass */
.StoreContent .Name {
  font-size: 1.4rem;
  color: #DBC28C;
  font-weight: 700;
}
/* line 45, ../sass/page/_kungfu-noodle.sass */
.StoreContent .Content {
  padding: 10px 30px;
  border-left: 2px #C5C5C5 dashed;
}

/* line 49, ../sass/page/_kungfu-noodle.sass */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
/* line 55, ../sass/page/_kungfu-noodle.sass */
.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 62, ../sass/page/_kungfu-noodle.sass */
#StorsSelect {
  width: 80%;
  margin: 20px auto 20px auto;
  display: block;
  background: url(../images/select_arrow.svg) right no-repeat white;
  appearance: none;
  padding: 5px 0px 5px 15px;
  color: #696969;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  /* line 73, ../sass/page/_kungfu-noodle.sass */
  .StoreContent {
    margin: 20px 0px 0px 8%;
    text-align: left;
  }
  /* line 76, ../sass/page/_kungfu-noodle.sass */
  .StoreContent p {
    font-size: 1rem;
    line-height: 2rem;
    color: #858585;
    margin-bottom: 0;
  }
  /* line 81, ../sass/page/_kungfu-noodle.sass */
  .StoreContent a {
    font-size: 1rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  /* line 85, ../sass/page/_kungfu-noodle.sass */
  .StoreContent .Name {
    font-size: 1.2rem;
    color: #DBC28C;
    font-weight: 700;
  }
  /* line 89, ../sass/page/_kungfu-noodle.sass */
  .StoreContent .Content {
    padding: 10px 30px;
    border-left: 2px #C5C5C5 dashed;
  }
}
/* line 1, ../sass/page/_heating-instructions.sass */
.heating {
  width: 90%;
  margin: 80px auto;
}
/* line 4, ../sass/page/_heating-instructions.sass */
.heating h1 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 1.875rem;
  color: #494949;
}

/* line 10, ../sass/page/_heating-instructions.sass */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
/* line 16, ../sass/page/_heating-instructions.sass */
.video-container .iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 23, ../sass/page/_heating-instructions.sass */
.heatingItem {
  padding-bottom: 3rem;
  margin-bottom: 1.5rem;
  border-bottom: 3px double #cf1227;
}

@media only screen and (max-width: 768px) {
  /* line 30, ../sass/page/_heating-instructions.sass */
  .heating h1 {
    font-size: 1.5rem;
  }
}
/* line 1, ../sass/page/_stores.sass */
.StoresContent {
  width: 90%;
  margin: 40px auto 60px auto;
}

/* line 5, ../sass/page/_stores.sass */
#StorsSelect_2 {
  width: 80%;
  margin: 0px auto 80px auto;
  background: url(../images/select_arrow.svg) right no-repeat white;
  appearance: none;
  padding: 5px 0px 5px 15px;
  color: #696969;
  font-size: 1rem;
  display: none;
}

@media only screen and (max-width: 768px) {
  /* line 16, ../sass/page/_stores.sass */
  .StorsSelectTable {
    display: none;
  }

  /* line 18, ../sass/page/_stores.sass */
  #StorsSelect_2 {
    display: block;
    margin: 0px auto 20px auto;
  }
}
/* line 1, ../sass/page/_productPage.sass */
.ProductIntroTitle {
  height: 190px;
  background-color: #c31327;
  background-size: cover;
  background-position: center center;
  text-align: center;
  margin: 80px auto 0 auto;
  background-attachment: fixed;
  padding-top: 70px;
}
/* line 11, ../sass/page/_productPage.sass */
.ProductIntroTitle p {
  color: white;
  font-size: 3em;
  font-weight: lighter;
  letter-spacing: 0.1em;
}
/* line 16, ../sass/page/_productPage.sass */
.ProductIntroTitle p.newYearTip {
  color: white;
  font-size: 2em;
  font-weight: 400;
}
/* line 21, ../sass/page/_productPage.sass */
.ProductIntroTitle p span.newYearTipSmall {
  font-size: 0.5em;
}

@media only screen and (max-width: 768px) {
  /* line 24, ../sass/page/_productPage.sass */
  .ProductIntroTitle {
    padding-top: 30px;
  }
  /* line 27, ../sass/page/_productPage.sass */
  .ProductIntroTitle p.newYearTip {
    font-size: 1.5em;
    padding: 0 30px;
  }
  /* line 31, ../sass/page/_productPage.sass */
  .ProductIntroTitle p span.newYearTipSmall {
    font-size: 0.5em;
  }
}
/* line 33, ../sass/page/_productPage.sass */
.ProductContent, .ShoppingCartContent, .SpecialsContent {
  width: 90%;
  margin: 0px auto 100px auto;
}
/* line 36, ../sass/page/_productPage.sass */
.ProductContent .Name, .ShoppingCartContent .Name, .SpecialsContent .Name {
  font-size: 1.6rem;
  color: #494949;
}
/* line 39, ../sass/page/_productPage.sass */
.ProductContent .DescriptionBox, .ShoppingCartContent .DescriptionBox, .SpecialsContent .DescriptionBox {
  margin: 10px 0px;
  padding: 30px 0px;
  border-top: 1px #C5C5C5 solid;
  border-bottom: 1px #C5C5C5 solid;
}
/* line 44, ../sass/page/_productPage.sass */
.ProductContent .DescriptionBox .ShippingDate, .ShoppingCartContent .DescriptionBox .ShippingDate, .SpecialsContent .DescriptionBox .ShippingDate {
  font-size: 1.2rem;
  color: red;
  font-weight: 500;
  margin-bottom: 20px;
}
/* line 49, ../sass/page/_productPage.sass */
.ProductContent .DescriptionBox .PackageDescription, .ShoppingCartContent .DescriptionBox .PackageDescription, .SpecialsContent .DescriptionBox .PackageDescription {
  margin-top: 60px;
}
/* line 51, ../sass/page/_productPage.sass */
.ProductContent .DescriptionBox p, .ShoppingCartContent .DescriptionBox p, .SpecialsContent .DescriptionBox p {
  font-size: 1.2rem;
  line-height: 1.8rem;
}
/* line 56, ../sass/page/_productPage.sass */
.ProductContent .DescriptionBox p span, .ShoppingCartContent .DescriptionBox p span, .SpecialsContent .DescriptionBox p span {
  font-size: 2rem;
  font-weight: normal;
}
/* line 60, ../sass/page/_productPage.sass */
.ProductContent .QuantityBar p, .ShoppingCartContent .QuantityBar p, .SpecialsContent .QuantityBar p {
  margin: 0px;
}
/* line 62, ../sass/page/_productPage.sass */
.ProductContent .QuantityBar .Minus, .ProductContent .QuantityBar .Plus, .ShoppingCartContent .QuantityBar .Minus, .ShoppingCartContent .QuantityBar .Plus, .SpecialsContent .QuantityBar .Minus, .SpecialsContent .QuantityBar .Plus {
  border: none;
  border-radius: 0px;
  cursor: pointer;
}
/* line 66, ../sass/page/_productPage.sass */
.ProductContent .QuantityBar .QuantityNumber, .ShoppingCartContent .QuantityBar .QuantityNumber, .SpecialsContent .QuantityBar .QuantityNumber {
  height: 40px;
  width: 100px;
  text-align: center;
  border: solid #DBC28C 1px;
}
/* line 71, ../sass/page/_productPage.sass */
.ProductContent .QuantityBar input, .ShoppingCartContent .QuantityBar input, .SpecialsContent .QuantityBar input {
  border-radius: 0px;
}
/* line 73, ../sass/page/_productPage.sass */
.ProductContent .QuantityBar input::-webkit-outer-spin-button,
.ProductContent .QuantityBar input::-webkit-inner-spin-button, .ShoppingCartContent .QuantityBar input::-webkit-outer-spin-button,
.ShoppingCartContent .QuantityBar input::-webkit-inner-spin-button, .SpecialsContent .QuantityBar input::-webkit-outer-spin-button,
.SpecialsContent .QuantityBar input::-webkit-inner-spin-button {
  appearance: none !important;
  margin: 0;
}
/* line 77, ../sass/page/_productPage.sass */
.ProductContent .BtnBox, .ShoppingCartContent .BtnBox, .SpecialsContent .BtnBox {
  margin: 20px 0;
}
/* line 79, ../sass/page/_productPage.sass */
.ProductContent .BtnBox .goSpecials, .ShoppingCartContent .BtnBox .goSpecials, .SpecialsContent .BtnBox .goSpecials {
  padding: 0;
}
/* line 81, ../sass/page/_productPage.sass */
.ProductContent .BtnBox .goSpecials a, .ShoppingCartContent .BtnBox .goSpecials a, .SpecialsContent .BtnBox .goSpecials a {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  color: #ffffff;
}
/* line 86, ../sass/page/_productPage.sass */
.ProductContent .BtnBox .goSpecials a:hover, .ShoppingCartContent .BtnBox .goSpecials a:hover, .SpecialsContent .BtnBox .goSpecials a:hover {
  color: #DBC28C;
  text-decoration: none;
}

/* line 89, ../sass/page/_productPage.sass */
.ProductIntroContent {
  width: 90%;
  margin: 100px auto 80px auto;
}
/* line 92, ../sass/page/_productPage.sass */
.ProductIntroContent h2 {
  margin-bottom: 30px;
  font-size: 1.6rem;
  color: #494949;
  font-weight: 400;
  line-height: 2.5rem;
}
/* line 98, ../sass/page/_productPage.sass */
.ProductIntroContent h3 {
  font-size: 1.2rem;
  line-height: 2.4rem;
  letter-spacing: 1px;
}
/* line 104, ../sass/page/_productPage.sass */
.ProductIntroContent p {
  font-size: 1.2rem;
  line-height: 2.5rem;
  text-align: justify;
}
/* line 110, ../sass/page/_productPage.sass */
.ProductIntroContent img {
  border-radius: 20px;
}

/* line 112, ../sass/page/_productPage.sass */
.TopLine {
  border-top: 1px #C5C5C5 solid;
  padding-top: 60px;
}

/* line 115, ../sass/page/_productPage.sass */
.ReportedContent {
  padding: 5%;
  background-color: #DBC28C;
}
/* line 118, ../sass/page/_productPage.sass */
.ReportedContent a, .ReportedContent p {
  font-size: 1.2rem;
  color: white;
  line-height: 2.5rem;
}

/* line 122, ../sass/page/_productPage.sass */
.Certificates {
  margin: 20px 0px;
  text-align: center;
}
/* line 125, ../sass/page/_productPage.sass */
.Certificates img {
  max-height: 400px;
  border: solid #DBC28C 1px;
  border-radius: 0px;
  text-align: center;
}

/* line 131, ../sass/page/_productPage.sass */
.GBtn {
  width: 100%;
  margin: 10px 15px 10px 0px;
  padding: 10px 40px;
  color: white;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background-color: #DBC28C;
  cursor: pointer;
}
/* line 141, ../sass/page/_productPage.sass */
.GBtn:hover {
  color: #DBC28C;
  border: 1px #DBC28C solid;
  background-color: white;
}
/* line 145, ../sass/page/_productPage.sass */
.GBtn:active {
  color: #9A7854;
  border: 1px #9A7854 solid;
  background-color: white;
}

/* line 150, ../sass/page/_productPage.sass */
.GLBtn {
  width: 100%;
  margin: 10px 15px 10px 0px;
  padding: 10px 40px;
  color: #DBC28C;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: white;
}
/* line 160, ../sass/page/_productPage.sass */
.GLBtn:hover {
  background-color: #DBC28C;
  color: white;
}
/* line 163, ../sass/page/_productPage.sass */
.GLBtn:active {
  background-color: #9A7854;
  color: white;
}

/* line 166, ../sass/page/_productPage.sass */
.hide {
  display: none;
}

/* line 2, ../sass/page/_login.sass */
.LoginBox {
  padding: 10% 5% 5% 5%;
}

/* line 5, ../sass/page/_login.sass */
.LoginBoxGold {
  height: 630px;
  padding: 0px 10% 0px 10%;
  border-width: 1px;
  border-style: solid;
  border-color: #C5A97B;
}

/* line 12, ../sass/page/_login.sass */
.LoginBoxGoldBanner {
  height: 600px;
}

/* line 15, ../sass/page/_login.sass */
.LoginBoxGoldBannerImg {
  background-size: cover;
  background-position: center center;
  background-image: url("../images/loginBanner_220318.webp");
  background-repeat: no-repeat;
  height: 100%;
}

/* line 22, ../sass/page/_login.sass */
.LoginBoxGoldImg {
  display: none;
}

/* line 25, ../sass/page/_login.sass */
.TextFacebookLogin {
  color: #ffffff;
  text-align: center;
  padding: 10px 0px 10px 0px;
  background-color: #2F5095;
  border-width: 1px;
  border-style: dashed;
  border-color: #ff0000;
}

/* line 35, ../sass/page/_login.sass */
.LoginTitle {
  text-align: center;
  line-height: 50px;
  color: #696969;
  font-size: 2rem;
  text-align: center;
  margin-top: 50px;
}

/* line 44, ../sass/page/_login.sass */
input {
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #D8D8D8;
  cursor: pointer;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #696969;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* line 63, ../sass/page/_login.sass */
.LoginText {
  float: right;
  padding: 0px 5px 0px 0px;
  font-size: 1rem;
  color: #DBC28C;
}
/* line 68, ../sass/page/_login.sass */
.LoginText:hover {
  color: #C5A97B;
}

/* line 71, ../sass/page/_login.sass */
.LoginBtn {
  width: 100%;
  padding: 10px;
  margin: 10px 0px 20px 0px;
  color: white;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background-color: #DBC28C;
  cursor: pointer;
}
/* line 81, ../sass/page/_login.sass */
.LoginBtn:hover {
  color: #DBC28C;
  border: 1px #DBC28C solid;
  background-color: white;
}
/* line 85, ../sass/page/_login.sass */
.LoginBtn:active {
  color: #9A7854;
  border: 1px #9A7854 solid;
  background-color: white;
}

/* line 91, ../sass/page/_login.sass */
.LoginBtnFB {
  width: 100%;
  padding: 10px;
  margin: 20px 0px 10px 0px;
  text-align: center;
  color: white;
  border: 1px #2F5095 solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background-color: #2F5095;
  cursor: pointer;
}
/* line 102, ../sass/page/_login.sass */
.LoginBtnFB span {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background: url("../images/fb-icon.svg") no-repeat;
  width: 15px;
  height: 17px;
  margin-right: 10px;
}
/* line 110, ../sass/page/_login.sass */
.LoginBtnFB:hover {
  color: #2F5095;
  border: 1px #2F5095 solid;
  background-color: white;
}
/* line 114, ../sass/page/_login.sass */
.LoginBtnFB:hover span {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background: url("../images/fb-icon-full.svg") no-repeat;
  width: 15px;
  height: 17px;
  margin-right: 10px;
}
/* line 122, ../sass/page/_login.sass */
.LoginBtnFB:active {
  color: #182E5E;
  border: 1px #182E5E solid;
  background-color: white;
}

/* line 128, ../sass/page/_login.sass */
.LoginBtnLine {
  width: 100%;
  padding: 10px;
  margin: 20px 0px 5px 0px;
  text-align: center;
  color: white;
  border: 1px #00C200 solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background-color: #00C200;
  cursor: pointer;
}
/* line 140, ../sass/page/_login.sass */
.LoginBtnLine span {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background: url("../images/line-icon.svg") no-repeat;
  width: 22px;
  height: 18px;
  margin-right: 10px;
}
/* line 148, ../sass/page/_login.sass */
.LoginBtnLine:hover {
  color: #00C200;
  border: 1px #00C200 solid;
  background-color: white;
}
/* line 152, ../sass/page/_login.sass */
.LoginBtnLine:hover span {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background: url("../images/line-icon-full.svg") no-repeat;
  width: 22px;
  height: 18px;
  margin-right: 10px;
}
/* line 160, ../sass/page/_login.sass */
.LoginBtnLine:active {
  color: #007F00;
  border: 1px #007F00 solid;
  background-color: white;
}

/* line 165, ../sass/page/_login.sass */
.LoginLine {
  display: none;
  width: 100%;
  height: 1px;
  background-color: #EDEDED;
}

@media only screen and (max-width: 768px) {
  /* line 172, ../sass/page/_login.sass */
  .LoginBoxGold {
    padding-top: 15px;
    padding-bottom: 15px;
    height: 850px;
  }

  /* line 176, ../sass/page/_login.sass */
  .LoginBoxGoldImg {
    display: block;
    width: 100%;
  }

  /* line 179, ../sass/page/_login.sass */
  .LoginBoxGoldBanner {
    display: none;
  }

  /* line 181, ../sass/page/_login.sass */
  .LoginTitle {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
/* line 1, ../sass/page/_forgotPassword.sass */
.PasswordText {
  color: #696969;
  font-size: 1rem;
}

/* line 5, ../sass/page/_forgotPassword.sass */
.ReturnBtn {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #DBC28C;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: white;
}
/* line 16, ../sass/page/_forgotPassword.sass */
.ReturnBtn:hover {
  background-color: #DBC28C;
  color: white;
}
/* line 19, ../sass/page/_forgotPassword.sass */
.ReturnBtn:active {
  background-color: #9A7854;
  color: white;
}

/* line 1, ../sass/page/_registration.sass */
.RegistrationTable {
  width: 90%;
  padding: 0px 30px 100px 30px;
  right: 0;
  left: 0;
  margin: auto;
}

/* line 8, ../sass/page/_registration.sass */
.RegistrationText {
  color: #696969;
  font-size: 1rem;
  margin-bottom: 0rem;
  line-height: 50px;
}

/* line 14, ../sass/page/_registration.sass */
.RegistrationTextRemarks {
  color: #696969;
  font-size: 1rem;
  margin-bottom: 0rem;
  margin-top: 10px;
}

/* line 20, ../sass/page/_registration.sass */
.TitleBoxTable {
  width: 90%;
  margin: 30px auto 0px auto;
  border-bottom: solid #C5C5C5 1px;
}
/* line 24, ../sass/page/_registration.sass */
.TitleBoxTable .Title {
  font-size: 1.5rem;
  color: #494949;
}
/* line 27, ../sass/page/_registration.sass */
.TitleBoxTable .DirectionTitle {
  color: #C5C5C5;
  padding-top: 10px;
  font-weight: lighter;
}

/* line 32, ../sass/page/_registration.sass */
.TableGoldLine {
  position: relative;
  width: 5px;
  height: 50px;
  background-color: #DBC28C;
}

/* line 38, ../sass/page/_registration.sass */
.ErrMessage {
  width: 160px;
  padding: 10px 20px 10px 20px;
  color: red;
  font-size: 1rem;
}

/* line 44, ../sass/page/_registration.sass */
.RegistrationBtnBox {
  text-align: center;
}

/* line 47, ../sass/page/_registration.sass */
.SendBtn_small {
  width: 90%;
  padding: 10px;
  margin: 10px 0px 20px 0px;
  color: white;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background-color: #DBC28C;
  cursor: pointer;
}
/* line 57, ../sass/page/_registration.sass */
.SendBtn_small:hover {
  color: #DBC28C;
  border: 1px #DBC28C solid;
  background-color: white;
}
/* line 61, ../sass/page/_registration.sass */
.SendBtn_small:active {
  color: #9A7854;
  border: 1px #9A7854 solid;
  background-color: white;
}

/* line 67, ../sass/page/_registration.sass */
.ReturnBtn_small {
  width: 90%;
  padding: 10px;
  margin: 10px 0px 20px 0px;
  font-size: 1.2rem;
  color: #DBC28C;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background: #ffffff;
  cursor: pointer;
}
/* line 78, ../sass/page/_registration.sass */
.ReturnBtn_small:hover {
  background-color: #DBC28C;
  color: white;
}
/* line 81, ../sass/page/_registration.sass */
.ReturnBtn_small:active {
  background-color: #9A7854;
  color: white;
}

/* line 86, ../sass/page/_registration.sass */
.RadioText {
  color: #696969;
  font-size: 1em;
}

/* line 91, ../sass/page/_registration.sass */
.TD1 {
  width: 30%;
}

/* line 93, ../sass/page/_registration.sass */
.TD2 {
  width: 5%;
}

/* line 95, ../sass/page/_registration.sass */
.TD3 {
  width: 65%;
}

/* line 97, ../sass/page/_registration.sass */
.TableRow {
  margin-right: 0;
}

/* line 101, ../sass/page/_registration.sass */
.RegistrationInput {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* line 1, ../sass/page/_orders.sass */
.OrdersContent {
  width: 90%;
  min-height: 350px;
  margin: 30px auto;
}
/* line 5, ../sass/page/_orders.sass */
.OrdersContent button {
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
}
/* line 10, ../sass/page/_orders.sass */
.OrdersContent button:hover {
  cursor: pointer;
  color: #d94454;
}
/* line 13, ../sass/page/_orders.sass */
.OrdersContent button:focus {
  outline: none;
}
/* line 15, ../sass/page/_orders.sass */
.OrdersContent .redeem {
  text-align: center;
  color: #d94454;
  font-size: 1.25rem;
  margin-top: 20px;
}
/* line 20, ../sass/page/_orders.sass */
.OrdersContent .OrdersItem {
  margin-top: 30px;
  padding-bottom: 10px;
  text-align: left;
  border: solid #DBC28C 1px;
}
/* line 25, ../sass/page/_orders.sass */
.OrdersContent .OrdersItem h1 {
  margin-bottom: 15px;
  padding: 15px 0 15px 40px;
  font-size: 1.1rem;
  background-color: #DBC28C;
  color: white;
}
/* line 31, ../sass/page/_orders.sass */
.OrdersContent .OrdersItem p {
  padding: 5px 0 0 40px;
  font-size: 1.1rem;
  color: #858585;
}
/* line 35, ../sass/page/_orders.sass */
.OrdersContent .OrdersItem p:hover {
  color: #d94454;
}
/* line 37, ../sass/page/_orders.sass */
.OrdersContent .ordersearchBtn {
  border: none;
  background-color: #DBC28C;
  width: 100%;
  text-align: center;
}
/* line 42, ../sass/page/_orders.sass */
.OrdersContent .ordersearchBtn:hover {
  cursor: pointer;
  color: #d94454;
}
/* line 45, ../sass/page/_orders.sass */
.OrdersContent .ordersearchBtn:focus {
  outline: none;
}

/* line 48, ../sass/page/_orders.sass */
.OrdersIdListContent {
  margin-top: 0px;
}
/* line 50, ../sass/page/_orders.sass */
.OrdersIdListContent .SearchInput, .OrdersIdListContent input {
  width: 95%;
  padding: 7px 0px 7px 15px;
  border-radius: 0px;
  border: solid #C5C5C5 1px;
  background: url(../images/search_icon.svg) right no-repeat white;
}
/* line 56, ../sass/page/_orders.sass */
.OrdersIdListContent .Select {
  width: 95%;
  padding: 7px 0px 7px 15px;
  border-radius: 0px;
  border: solid #C5C5C5 1px;
  background: url(../images/select_arrow.svg) right no-repeat white;
  appearance: none;
}

/* line 63, ../sass/page/_orders.sass */
.OrderContent {
  width: 90%;
  margin: 30px auto;
}
/* line 66, ../sass/page/_orders.sass */
.OrderContent .OrderMethod {
  margin-bottom: 30px;
}
/* line 68, ../sass/page/_orders.sass */
.OrderContent .OrderMethod p {
  margin-bottom: 0px;
  padding: 0px 30px 0px 0px;
  font-size: 1.1rem;
  line-height: 2rem;
  color: #858585;
}
/* line 74, ../sass/page/_orders.sass */
.OrderContent .OrderMethod .Status {
  padding: 10px 20px;
  border: solid #0043CD 1px;
  color: #0043CD;
}
/* line 78, ../sass/page/_orders.sass */
.OrderContent .TableList {
  margin: 30px auto 0px auto;
}
/* line 80, ../sass/page/_orders.sass */
.OrderContent .Amount {
  margin-bottom: 30px;
  padding: 30px 30px 30px 0px;
  background-color: #F7F7F7;
  text-align: right;
  border: solid #DBC28C 1px;
  border-top: none;
}
/* line 87, ../sass/page/_orders.sass */
.OrderContent .Amount p {
  margin-bottom: 0px;
  font-size: 1.1rem;
  line-height: 2.5rem;
  color: #858585;
}
/* line 92, ../sass/page/_orders.sass */
.OrderContent .Amount p span {
  color: red;
}
/* line 94, ../sass/page/_orders.sass */
.OrderContent .Amount .BalanceDue {
  font-size: 1.6rem;
}

@media only screen and (max-width: 768px) {
  /* line 100, ../sass/page/_orders.sass */
  .OrdersContent .redeem {
    margin-top: 0px;
  }
  /* line 102, ../sass/page/_orders.sass */
  .OrdersContent .OrdersItem {
    margin-top: 20px;
  }

  /* line 104, ../sass/page/_orders.sass */
  .OrdersIdListContent {
    margin-top: 20px;
  }
  /* line 106, ../sass/page/_orders.sass */
  .OrdersIdListContent .SearchInput, .OrdersIdListContent input {
    width: 100%;
  }
  /* line 108, ../sass/page/_orders.sass */
  .OrdersIdListContent .Select {
    width: 100%;
  }
}
/* line 2, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar {
  width: 100%;
  margin: 30px auto 30px auto;
  right: 0;
  left: 0;
  margin: auto;
}
/* line 8, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .Name {
  font-size: 1.4rem;
  color: #494949;
}
/* line 11, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .DescriptionBox {
  margin: 10px 0px;
  padding: 30px 0px;
  border-top: 1px #C5C5C5 solid;
  border-bottom: 1px #C5C5C5 solid;
}
/* line 16, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .DescriptionBox .PackageDescription {
  margin-top: 60px;
}
/* line 18, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .DescriptionBox p {
  font-size: 1rem;
  color: #858585;
  font-weight: lighter;
  line-height: 1.8rem;
}
/* line 23, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .DescriptionBox p span {
  font-size: 2rem;
  font-weight: normal;
}
/* line 27, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .QuantityBar p {
  margin: 0px;
}
/* line 29, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .QuantityBar .Minus, .ShoppingCartContent_headerbar .QuantityBar .Plus {
  border: none;
  border-radius: 0px;
  cursor: pointer;
}
/* line 33, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .QuantityBar .QuantityNumber {
  height: 40px;
  width: 60px;
  text-align: center;
  border: solid #DBC28C 1px;
}
/* line 38, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .QuantityBar input {
  border-radius: 0px;
  margin: 0;
}
/* line 41, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .QuantityBar input::-webkit-outer-spin-button,
.ShoppingCartContent_headerbar .QuantityBar input::-webkit-inner-spin-button {
  appearance: none !important;
  margin: 0;
}
/* line 45, ../sass/page/_shoppingCart.sass */
.ShoppingCartContent_headerbar .BtnBox {
  margin: 20px 0;
}

/* line 48, ../sass/page/_shoppingCart.sass */
.ShoppingCartBox {
  z-index: 9999;
  position: absolute;
  top: 88px;
  right: 0px;
  max-width: 400px;
  height: auto;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
  padding: 20px;
}

/* line 61, ../sass/page/_shoppingCart.sass */
.ShoppingCartOverflow {
  max-height: 320px;
  overflow: auto;
}

/* line 65, ../sass/page/_shoppingCart.sass */
.ShoppingCartDividers {
  width: 100%;
  height: 1px;
  background-color: #C5C5C5;
  margin: 20px 0px 20px 0px;
}

/* line 72, ../sass/page/_shoppingCart.sass */
.ShoppingCartCommodityBlock {
  height: 100px;
  right: 0;
  left: 0;
  margin: auto;
}

/* line 78, ../sass/page/_shoppingCart.sass */
.ShoppingCartText {
  font-style: normal;
  color: #696969;
  font-size: 1rem;
  font-weight: lighter;
}

/* line 87, ../sass/page/_shoppingCart.sass */
.CheckCartBtnBox {
  position: relative;
  padding: 10px 0px 10px 0px;
}

/* line 91, ../sass/page/_shoppingCart.sass */
.CheckCartBtn {
  right: 0;
  padding: 10px 15px;
  color: white;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1rem;
  background-color: #DBC28C;
  cursor: pointer;
}
/* line 100, ../sass/page/_shoppingCart.sass */
.CheckCartBtn:hover {
  color: #DBC28C;
  border: 1px #DBC28C solid;
  background-color: white;
}
/* line 104, ../sass/page/_shoppingCart.sass */
.CheckCartBtn:active {
  color: #9A7854;
  border: 1px #9A7854 solid;
  background-color: white;
}

/* line 114, ../sass/page/_shoppingCart.sass */
.PriceBox {
  width: 100px;
}

/* line 117, ../sass/page/_shoppingCart.sass */
.ShoppingCartImgBox {
  height: 100px;
  max-width: 100%;
  margin: 0 auto 20px auto;
  padding: 5px;
  border: solid #DBC28C 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* line 126, ../sass/page/_shoppingCart.sass */
.ShoppingCartImgBox img {
  max-width: 100%;
  max-height: 100%;
}

/* line 130, ../sass/page/_shoppingCart.sass */
.ShoppingCartTitle {
  font-size: 1rem;
  font-weight: bold;
  line-height: 22px;
  color: #383838;
  height: 44px;
  margin-bottom: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* line 143, ../sass/page/_shoppingCart.sass */
.ShoppingCartPrice {
  font-size: 1.5rem;
  line-height: 22px;
  color: red;
  padding: 9px;
  text-align: right;
}

/* line 151, ../sass/page/_shoppingCart.sass */
.CheckCartBtnDelete {
  width: 100px;
  padding: 8px;
  color: #DBC28C;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1rem;
  cursor: pointer;
  background-color: white;
}
/* line 160, ../sass/page/_shoppingCart.sass */
.CheckCartBtnDelete:hover {
  background-color: #DBC28C;
  color: white;
}
/* line 163, ../sass/page/_shoppingCart.sass */
.CheckCartBtnDelete:active {
  background-color: #9A7854;
  color: white;
}

/* line 167, ../sass/page/_shoppingCart.sass */
.ShoppingCartIcon {
  position: relative;
}
/* line 169, ../sass/page/_shoppingCart.sass */
.ShoppingCartIcon .RedBadge {
  position: absolute;
  background-color: red;
  color: white;
  font-size: 0.6rem;
  top: -15px;
  right: -10px;
  padding: 3px 8px;
  border-radius: 20px;
  font-style: normal;
}

/* line 182, ../sass/page/_shoppingCart.sass */
.account-user {
  position: relative;
}

/* line 184, ../sass/page/_shoppingCart.sass */
.loginPoint {
  color: #cf1227;
  font-weight: bolder;
  position: absolute;
  font-size: 0.6rem;
  text-align: right;
  right: 0;
  bottom: -1rem;
  padding-right: 0.5rem;
  border-radius: 20px;
  font-style: normal;
  width: 10rem;
}

@media only screen and (max-width: 768px) {
  /* line 197, ../sass/page/_shoppingCart.sass */
  .loginPoint {
    position: absolute;
    bottom: -0.7rem;
  }
}
/* line 201, ../sass/page/_shoppingCart.sass */
.AccountBox {
  z-index: 9999;
  position: absolute;
  top: 88px;
  right: 0px;
  height: auto;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
  padding-top: 10px;
}

/* line 214, ../sass/page/_shoppingCart.sass */
.AccountText {
  cursor: pointer;
  width: 10rem;
  height: 50px;
  line-height: 50px;
  font-style: normal;
  color: #696969;
  font-size: 1rem;
  margin-bottom: 0rem;
  font-weight: lighter;
  position: relative;
  text-align: center;
}
/* line 226, ../sass/page/_shoppingCart.sass */
.AccountText:hover {
  color: #DBC28C;
}
/* line 228, ../sass/page/_shoppingCart.sass */
.AccountText a {
  text-align: center;
  color: #696969;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* line 236, ../sass/page/_shoppingCart.sass */
.AccountText a:hover {
  color: #DBC28C;
  text-decoration: none;
}
/* line 239, ../sass/page/_shoppingCart.sass */
.AccountText.tip {
  color: #cf1227;
  cursor: default;
}
/* line 242, ../sass/page/_shoppingCart.sass */
.AccountText + .AccountText {
  border-top: 1px solid #DBC28C;
}

/* line 244, ../sass/page/_shoppingCart.sass */
.arrow {
  position: absolute;
  color: #ffffff;
  width: 0px;
  height: 0px;
  line-height: 0px;
  border-width: 20px 15px 0;
  border-style: solid dashed dashed dashed;
  border-left-color: transparent;
  border-right-color: transparent;
  top: -20px;
  right: 20px;
  transform: rotateX(180deg);
}

/* line 258, ../sass/page/_shoppingCart.sass */
.arrow-border {
  color: #DBC28C;
  top: -20px;
  right: 80px;
}

/* line 263, ../sass/page/_shoppingCart.sass */
.arrow-bg {
  color: #ffffff;
  top: -18px;
  right: 80px;
}

/* line 268, ../sass/page/_shoppingCart.sass */
.arrowuser {
  position: absolute;
  color: #ffffff;
  width: 0px;
  height: 0px;
  line-height: 0px;
  border-width: 20px 15px 0;
  border-style: solid dashed dashed dashed;
  border-left-color: transparent;
  border-right-color: transparent;
  top: -20px;
  right: 20px;
  transform: rotateX(180deg);
}

/* line 282, ../sass/page/_shoppingCart.sass */
.arrowuser-border {
  color: #DBC28C;
  top: -20px;
  right: 10px;
}

/* line 287, ../sass/page/_shoppingCart.sass */
.arrowuser-bg {
  color: #ffffff;
  top: -18px;
  right: 10px;
}

/* line 2, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepBox {
  position: relative;
  margin: 30px auto 30px auto;
  width: 60%;
  height: 100px;
}

/* line 8, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepText_1 {
  position: absolute;
  width: 60px;
  text-align: center;
}

/* line 13, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepText_2 {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}

/* line 21, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepText_3 {
  position: absolute;
  right: 0%;
  width: 100%;
  text-align: right;
}

/* line 27, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepImg_1 {
  position: absolute;
  top: 30px;
  width: 60px;
}
/* line 31, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepImg_1:hover {
  cursor: pointer;
}

/* line 34, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepImg_2 {
  position: absolute;
  top: 30px;
  right: 0;
  left: 0;
  margin: auto;
  width: 60px;
}
/* line 41, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepImg_2:hover {
  cursor: pointer;
}

/* line 44, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepImg_3 {
  position: absolute;
  top: 30px;
  right: 0%;
  width: 60px;
}

/* line 51, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepBar_1-1 {
  z-index: -1;
  position: absolute;
  width: 50%;
  height: 5px;
  top: 56px;
  background-color: #DBC28C;
}

/* line 59, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepBar_1-2 {
  z-index: -1;
  position: absolute;
  width: 50%;
  height: 5px;
  top: 56px;
  background-color: #ABABAB;
}

/* line 67, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepBar_2-1 {
  z-index: -1;
  position: absolute;
  width: 50%;
  height: 5px;
  top: 56px;
  left: 50%;
  background-color: #DBC28C;
}

/* line 76, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressStepBar_2-2 {
  z-index: -1;
  position: absolute;
  width: 50%;
  height: 5px;
  top: 56px;
  left: 50%;
  background-color: #ABABAB;
}

/* line 88, ../sass/page/_shoppingProgress.sass */
.tableBox {
  width: 90%;
  margin: 30px auto 30px auto;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
}

/* line 95, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTotalPrice {
  width: 100%;
  background-color: #F7F7F7;
  border-width: 1px;
  border-top-style: solid;
  border-color: #DBC28C;
  text-align: right;
  padding: 10px;
}
/* line 103, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTotalPrice .redeem {
  color: #696969;
  margin-bottom: 5px;
}
/* line 106, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTotalPrice .redeem .redeemTip {
  background-color: transparent;
  border: none;
  padding: 0 5px;
}

/* line 111, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTotalPriceTextGray {
  color: #696969;
  font-size: 1.5rem;
}

/* line 115, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTotalPriceTextRed {
  color: red;
  font-size: 1.5rem;
}

/* line 119, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressBtnBox {
  width: 90%;
  padding: 0px 0px 30px 0px;
}

/* line 123, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressBtnSendBtn_small {
  width: 100%;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  color: white;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background-color: #DBC28C;
  cursor: pointer;
}
/* line 133, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressBtnSendBtn_small:hover {
  color: #DBC28C;
  border: 1px #DBC28C solid;
  background-color: white;
}
/* line 137, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressBtnSendBtn_small:active {
  color: #9A7854;
  border: 1px #9A7854 solid;
  background-color: white;
}

/* line 143, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressReturnBtn_small {
  width: 100%;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  font-size: 1.2rem;
  color: #DBC28C;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  background: #ffffff;
  cursor: pointer;
}
/* line 154, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressReturnBtn_small:hover {
  background-color: #DBC28C;
  color: white;
}
/* line 157, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressReturnBtn_small:active {
  background-color: #9A7854;
  color: white;
}

/* line 161, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressPhoto {
  margin: 0 auto 0px auto;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
  background-color: #ffffff;
}
/* line 169, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressPhoto img {
  max-width: auto;
  max-height: 160px;
}

/* line 173, ../sass/page/_shoppingProgress.sass */
.ThPhoto {
  width: 20%;
}

/* line 176, ../sass/page/_shoppingProgress.sass */
.ThModify {
  width: 20%;
}

/* line 179, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressText {
  color: #696969;
  font-size: 1.2rem;
  font-weight: lighter;
}

/* line 184, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressModify {
  width: 100%;
}
/* line 187, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressModify p {
  margin: 0px;
}
/* line 189, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressModify .Minus, .ShoppingProgressModify .Plus {
  border: none;
  border-radius: 0px;
  cursor: pointer;
}
/* line 193, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressModify .QuantityNumber {
  height: 40px;
  width: 100%;
  text-align: center;
  border: solid #DBC28C 1px;
}
/* line 198, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressModify input {
  border-radius: 0px;
}
/* line 200, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressModify input::-webkit-outer-spin-button,
.ShoppingProgressModify input::-webkit-inner-spin-button {
  appearance: none !important;
  margin: 0;
}
/* line 204, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressModify .BtnBox {
  margin: 20px 0;
}

/* line 208, ../sass/page/_shoppingProgress.sass */
.rwd-table {
  overflow: hidden;
  min-width: 100%;
}
/* line 212, ../sass/page/_shoppingProgress.sass */
.rwd-table tr:nth-of-type(2n) {
  background: #eee;
}
/* line 214, ../sass/page/_shoppingProgress.sass */
.rwd-table th, .rwd-table td {
  margin: 0.5em 1em;
  padding: 0.25em 0.5em;
}
/* line 218, ../sass/page/_shoppingProgress.sass */
.rwd-table th {
  display: none;
  background-color: #DBC28C;
  color: #ffffff;
  font-size: 1.2rem;
}
/* line 223, ../sass/page/_shoppingProgress.sass */
.rwd-table td {
  display: block;
}
/* line 225, ../sass/page/_shoppingProgress.sass */
.rwd-table td:before {
  content: attr(data-th);
  width: 6.5em;
  display: inline-block;
}

@media only screen and (min-width: 1024px) {
  /* line 232, ../sass/page/_shoppingProgress.sass */
  .rwd-table th, .rwd-table td {
    padding: 1em;
    display: table-cell;
  }
  /* line 240, ../sass/page/_shoppingProgress.sass */
  .rwd-table td:before {
    display: none;
    background-color: #DBC28C;
    color: #ffffff;
    font-size: 1.2rem;
  }
}
/* line 249, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTable {
  width: 90%;
  right: 0;
  left: 0;
  margin: 40px auto 40px auto;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
}
/* line 257, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTable input[type="text"]:required {
  outline-color: red;
}
/* line 259, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTable input[type="text"]:focus {
  outline-color: #005fcc;
}
/* line 262, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTable .newYearDelivery {
  display: flex;
}
/* line 264, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTable .newYearDelivery p {
  width: 380px;
  margin-left: 60px;
}
@media only screen and (max-width: 768px) {
  /* line 268, ../sass/page/_shoppingProgress.sass */
  .ShoppingProgressTable .newYearDelivery {
    flex-direction: column;
  }
  /* line 270, ../sass/page/_shoppingProgress.sass */
  .ShoppingProgressTable .newYearDelivery p {
    width: 100%;
    margin-left: 0;
  }
}

/* line 274, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTitleTextBox {
  width: 100%;
  background-color: #DBC28C;
  padding: 10px;
  margin-left: 0px;
}

/* line 280, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTitleText {
  color: #ffffff;
  font-size: 1.5rem;
}

/* line 284, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTotalPriceTextRed {
  color: red;
  font-size: 1.5rem;
}

/* line 288, ../sass/page/_shoppingProgress.sass */
.ant-input {
  font-size: 1rem;
  padding: 10px;
  height: 40px;
}

/* line 293, ../sass/page/_shoppingProgress.sass */
.ant-input:focus {
  outline-color: #ff0000;
  outline-width: 2px;
  box-shadow: none;
}

/* line 298, ../sass/page/_shoppingProgress.sass */
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-input:hover {
  border-color: #ff0000;
  border-width: 2px;
}

/* line 303, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressCheckbox {
  right: 0;
  color: #ffffff;
  font-size: 1.2rem;
  text-align: right;
}
/* line 308, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressCheckbox .asPurchaser {
  transform: scale(1.5);
  margin-right: 30px;
}

/* line 312, ../sass/page/_shoppingProgress.sass */
#categorySelectShopping {
  width: 200px;
  display: block;
}

/* line 317, ../sass/page/_shoppingProgress.sass */
#categorySelectShopping {
  background: url(../images/select_arrow.svg) right no-repeat white;
  appearance: none;
  padding: 5px 0px 5px 15px;
  color: #696969;
  font-size: 1rem;
}

/* line 324, ../sass/page/_shoppingProgress.sass */
#categorySelectTCatStation {
  width: 200px;
  display: block;
}

/* line 329, ../sass/page/_shoppingProgress.sass */
#categorySelectTCatStation {
  background: url(../images/select_arrow.svg) right no-repeat white;
  appearance: none;
  padding: 5px 0px 5px 15px;
  color: #696969;
  font-size: 1rem;
}

/* line 336, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressDescription {
  padding-bottom: 120px;
}
/* line 338, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressDescription li {
  padding-left: 1.1em;
  text-indent: -1.1em;
  color: #696969;
  font-size: 1rem;
  line-height: 1.85rem;
}

/* line 345, ../sass/page/_shoppingProgress.sass */
.ant-input {
  border-width: 2px;
  font-size: 1rem;
  padding: 10px;
  margin: 10px;
}

/* line 354, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressDescriptionTitle {
  color: #383838;
  font-size: 1.25rem;
  line-height: 50px;
}

/* line 360, ../sass/page/_shoppingProgress.sass */
.RegistrationTextGiftsDescription {
  color: #696969;
  font-size: 1rem;
  line-height: 40px;
  margin: 10px;
  background-color: #EDEDED;
}

/* line 367, ../sass/page/_shoppingProgress.sass */
.RegistrationTextTitle {
  color: #696969;
  font-size: 1rem;
  line-height: 30px;
  padding: 10px;
}

/* line 374, ../sass/page/_shoppingProgress.sass */
.RegistrationTextBox {
  float: left;
  padding: 10px 20px 10px 20px;
  margin: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
  background-color: #ffffff;
}

/* line 383, ../sass/page/_shoppingProgress.sass */
.RegistrationTextInput {
  position: relative;
  margin: 0px 70px 20px 70px;
}

/* line 387, ../sass/page/_shoppingProgress.sass */
.RegistrationTextGifts {
  text-align: center;
}

/* line 390, ../sass/page/_shoppingProgress.sass */
.HiddenDatepicker {
  position: absolute;
  top: -10px;
  height: 40px;
  width: 200px;
  left: 25px;
  z-index: -1;
  border: 0px;
}
/* line 398, ../sass/page/_shoppingProgress.sass */
.HiddenDatepicker:focus {
  outline: none !important;
  border: 0px;
}

/* line 402, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressPhotoGifts {
  margin: 0 auto 0px auto;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
  background-color: #ffffff;
}
/* line 410, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressPhotoGifts img {
  max-width: auto;
  max-height: 80px;
}

/* line 415, ../sass/page/_shoppingProgress.sass */
.RegistrationInputGifts {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* line 419, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressReturnBtnGifts_small {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #DBC28C;
  border: 1px #DBC28C solid;
  border-radius: 0px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: white;
}
/* line 430, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressReturnBtnGifts_small:hover {
  background-color: #DBC28C;
  color: white;
}
/* line 433, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressReturnBtnGifts_small:active {
  background-color: #9A7854;
  color: white;
}

/* line 439, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTextarea {
  width: 90%;
  border-style: solid;
  border-color: #DBC28C;
  background: #ffffff;
  padding: 10px;
  margin: 10px 0px 10px 0px;
}
/* line 446, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTextarea::placeholder {
  color: #ccc;
}

/* line 449, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTermsBox {
  position: relative;
  padding: 10px;
}
/* line 452, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTermsBox label.normalFont {
  font-size: 20px;
}
/* line 454, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTermsBox label.normalFont span {
  display: inline-block;
  margin-left: 20px;
}
/* line 457, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTermsBox label.normalFont input[type="checkbox"] {
  margin: 0;
  position: absolute;
  top: 6px;
  transform: scale(1.5);
}

/* line 463, ../sass/page/_shoppingProgress.sass */
.ShoppingProgressTerms {
  width: 90%;
  right: 0;
  left: 0;
  margin: auto;
  color: #696969;
}

/* line 472, ../sass/page/_shoppingProgress.sass */
.GiftsBox {
  margin-left: auto;
  padding: 20px;
  width: 160px;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
}

/* line 482, ../sass/page/_shoppingProgress.sass */
.ShoppingStep4AllBox {
  padding: 0px 0px 50px 0px;
}

/* line 485, ../sass/page/_shoppingProgress.sass */
.ShoppingStep4TitleBox {
  width: 70%;
  right: 0;
  left: 0;
  margin: auto;
  padding: 20px;
}

/* line 492, ../sass/page/_shoppingProgress.sass */
.ShoppingStep4TextBox {
  width: 70%;
  right: 0;
  left: 0;
  margin: auto;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #DBC28C;
}
/* line 501, ../sass/page/_shoppingProgress.sass */
.ShoppingStep4TextBox img {
  padding: 25px 0px 25px 0px;
}

/* line 504, ../sass/page/_shoppingProgress.sass */
.ShoppingStep4Title {
  color: #696969;
  font-size: 1.25rem;
  line-height: 40px;
  text-align: center;
}

/* line 511, ../sass/page/_shoppingProgress.sass */
.ShoppingStep4TextTitle {
  color: #696969;
  font-size: 1.25rem;
  line-height: 40px;
}

/* line 516, ../sass/page/_shoppingProgress.sass */
.ShoppingStep4Text {
  color: #696969;
  font-size: 1.25rem;
  font-weight: lighter;
  line-height: 40px;
}

/* line 522, ../sass/page/_shoppingProgress.sass */
.BOX {
  height: 40px;
  right: 0;
  left: 0;
  margin: auto;
  border-width: 1px;
  border-style: dashed;
  border-color: #ff0000;
}

/* line 531, ../sass/page/_shoppingProgress.sass */
label.normalFont {
  font-size: 16px;
}

/* line 534, ../sass/page/_shoppingProgress.sass */
.termsLink {
  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
}
/* line 539, ../sass/page/_shoppingProgress.sass */
.termsLink:hover {
  color: #C5A97B;
}

/* line 541, ../sass/page/_shoppingProgress.sass */
.termsContent {
  width: 100%;
  display: block;
  line-height: 1.5;
}

/* line 546, ../sass/page/_shoppingProgress.sass */
.tips {
  font-size: 14px;
  color: #c3a88b;
}

/* line 549, ../sass/page/_shoppingProgress.sass */
.tipsPoint {
  color: #ff0000;
  margin-bottom: 0.5rem;
}

/* line 553, ../sass/page/_shoppingProgress.sass */
.Logo_BlackCat {
  background: url("../images/logo_BlackCat.png");
  width: 130px;
  height: 38px;
}

/* line 558, ../sass/page/_shoppingProgress.sass */
.Logo_Card {
  background: url("../images/VISA-Master.svg") no-repeat;
  width: 150px;
  height: 38px;
}

@media only screen and (max-width: 768px) {
  /* line 565, ../sass/page/_shoppingProgress.sass */
  .ShoppingRWDText {
    font-size: 1.2rem !important;
    margin-bottom: 0rem !important;
    line-height: 1.8rem !important;
  }

  /* line 569, ../sass/page/_shoppingProgress.sass */
  .TablePadding {
    width: 90%;
    right: 0;
    left: 0;
    margin: 40px auto 0px auto;
    border-width: 1px;
    border-style: solid;
    border-color: #DBC28C;
  }

  /* line 578, ../sass/page/_shoppingProgress.sass */
  .ShoppingProgressTermsBox label.normalFont input[type="checkbox"] {
    top: 9px;
  }
}
/* line 1, ../sass/page/_coupons.sass */
.CouponContent {
  width: 90%;
  margin: 40px auto;
}
/* line 4, ../sass/page/_coupons.sass */
.CouponContent .CouponCount {
  padding: 15px 30px;
  background-color: #EDEDED;
}
/* line 7, ../sass/page/_coupons.sass */
.CouponContent .CouponCount p {
  margin-bottom: 0px;
  padding-left: 20px;
  font-size: 1.2rem;
  line-height: 2rem;
  color: #858585;
}
/* line 13, ../sass/page/_coupons.sass */
.CouponContent .CouponCount .CouponCountNumber {
  color: #006CFC;
  font-size: 1.8rem;
  font-weight: bold;
}

/* line 1, ../sass/page/_mainAdvertising.sass */
.Adv_box {
  width: 100%;
  padding: 10px;
  margin-right: 0px;
  margin-left: 0px;
}

/* line 7, ../sass/page/_mainAdvertising.sass */
.AdvContentBox {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

/* line 13, ../sass/page/_mainAdvertising.sass */
.AdvImgBox {
  width: 100%;
  margin: auto;
  position: relative;
}
/* line 17, ../sass/page/_mainAdvertising.sass */
.AdvImgBox img {
  width: 100%;
  height: auto;
}

/* line 21, ../sass/page/_mainAdvertising.sass */
.AdvTextBox {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.4s;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 60px;
}
/* line 29, ../sass/page/_mainAdvertising.sass */
.AdvTextBox:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* line 34, ../sass/page/_mainAdvertising.sass */
.AdvTitle {
  font-size: 1.6rem;
  color: #ffffff;
  text-align: center;
}

/* line 39, ../sass/page/_mainAdvertising.sass */
.AdvText {
  font-size: 1rem;
  color: #ffffff;
  font-weight: lighter;
  font-weight: 400;
}

/* line 47, ../sass/page/_mainAdvertising.sass */
.AdvTextBold {
  font-size: 1.6rem;
  color: #494949;
  line-height: 2.4rem;
}

/* line 53, ../sass/page/_mainAdvertising.sass */
.AdvBtn-wrap {
  display: inline-block;
  width: 100%;
  text-align: center;
}

/* line 57, ../sass/page/_mainAdvertising.sass */
.AdvBtn {
  margin: auto;
  padding: 10px 40px;
  color: white;
  border: 1px #ba0d15 solid;
  border-radius: 25px;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0 8px 6px -6px #380406;
  background-color: #ba0d15;
  transition: background-color 0.4s;
}
/* line 68, ../sass/page/_mainAdvertising.sass */
.AdvBtn:hover {
  color: white;
  border: 1px #dc000a solid;
  background-color: #dc000a;
  text-shadow: 0px 0px 10px #380406;
}
/* line 74, ../sass/page/_mainAdvertising.sass */
.AdvBtn:active {
  color: #9A7854;
  border: 1px #9A7854 solid;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  /* line 81, ../sass/page/_mainAdvertising.sass */
  .Adv_box {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  /* line 85, ../sass/page/_mainAdvertising.sass */
  .AdvContentBox {
    width: 100%;
  }

  /* line 87, ../sass/page/_mainAdvertising.sass */
  .AdvImgBox {
    width: 100%;
  }

  /* line 89, ../sass/page/_mainAdvertising.sass */
  .AdvTextBox {
    margin: 0px;
    min-height: auto;
  }

  /* line 92, ../sass/page/_mainAdvertising.sass */
  .AdvBtn {
    width: 100%;
  }
}
@media only screen and (max-width: 1600px) {
  /* line 96, ../sass/page/_mainAdvertising.sass */
  .AdvContentBox {
    margin: 0px;
  }
}
/* line 2, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text {
  width: 960px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  line-height: 2rem;
}
/* line 8, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text .delivery {
  font-size: 1.25rem;
  line-height: 2.25rem;
  width: 65%;
}
/* line 12, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text .storesTel {
  width: 35%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
}
/* line 17, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text .storesTel a {
  line-height: 2rem;
}
/* line 19, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text .storesTel a:hover {
  text-decoration: none;
}
/* line 21, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text .storesTel a i {
  margin-right: 5px;
}
/* line 23, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text .storesTel a span {
  display: inline-block;
  width: 120px;
}
/* line 27, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text .link {
  width: 250px;
  display: block;
}
/* line 30, ../sass/page/_deliveryPage.sass */
#page-delivery .popular-text .link img {
  width: 100%;
}
/* line 32, ../sass/page/_deliveryPage.sass */
#page-delivery .img {
  width: 960px;
  margin: auto;
}
/* line 35, ../sass/page/_deliveryPage.sass */
#page-delivery .img img {
  width: 100%;
  display: inline-block;
  margin: 15px 0;
}

@media only screen and (max-width: 768px) {
  /* line 43, ../sass/page/_deliveryPage.sass */
  #page-delivery .popular-text {
    width: 100%;
    padding: 0 30px;
    flex-direction: column;
  }
  /* line 47, ../sass/page/_deliveryPage.sass */
  #page-delivery .popular-text .delivery {
    font-size: 1rem;
    line-height: 2rem;
    width: 100%;
  }
  /* line 51, ../sass/page/_deliveryPage.sass */
  #page-delivery .popular-text .storesTel {
    width: 100%;
    padding: 0 10px;
    margin-top: 10px;
  }
  /* line 56, ../sass/page/_deliveryPage.sass */
  #page-delivery .popular-text .storesTel a i {
    margin-right: 10px;
  }
  /* line 58, ../sass/page/_deliveryPage.sass */
  #page-delivery .popular-text .link {
    margin: auto;
    margin-top: 10px;
  }
  /* line 61, ../sass/page/_deliveryPage.sass */
  #page-delivery .img {
    width: 100%;
  }
}
/* line 1, ../sass/page/_aboutUsPage.sass */
.AboutUsContent {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 120px;
}
/* line 5, ../sass/page/_aboutUsPage.sass */
.AboutUsContent h1 {
  margin-bottom: 30px;
  font-size: 1.6rem;
  color: #494949;
  text-align: center;
  line-height: 2.5rem;
}
/* line 11, ../sass/page/_aboutUsPage.sass */
.AboutUsContent h2 {
  margin-bottom: 30px;
  font-size: 1.4rem;
  color: #494949;
  font-weight: 400;
  line-height: 2.5rem;
}
/* line 17, ../sass/page/_aboutUsPage.sass */
.AboutUsContent h3 {
  margin-bottom: 30px;
  font-size: 1.4rem;
  color: #494949;
  text-align: right;
  line-height: 2.8rem;
}
/* line 23, ../sass/page/_aboutUsPage.sass */
.AboutUsContent p {
  font-size: 1rem;
  color: #858585;
  line-height: 1.85rem;
  text-align: justify;
}
/* line 28, ../sass/page/_aboutUsPage.sass */
.AboutUsContent .AwardText {
  bottom: 10px;
  padding-bottom: 3%;
  color: white;
  font-size: 1.6rem;
  font-weight: lighter;
  text-align: center;
}
/* line 35, ../sass/page/_aboutUsPage.sass */
.AboutUsContent .AwardText p {
  color: white;
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: lighter;
  text-align: center;
}
/* line 41, ../sass/page/_aboutUsPage.sass */
.AboutUsContent .SliderBoxheight {
  border: 1px solid #DBC28C;
  width: 1000px;
  height: 750px;
  background-color: black;
}
/* line 46, ../sass/page/_aboutUsPage.sass */
.AboutUsContent .SliderBoxheight img {
  width: 100%;
  height: 750px;
  object-fit: contain;
  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  /* line 54, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent .AwardText p {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  /* line 57, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent .SliderBoxheight {
    width: 600px;
    height: 450px;
  }
  /* line 60, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent .SliderBoxheight img {
    height: 450px;
  }
}
@media only screen and (max-width: 576px) {
  /* line 63, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent {
    width: 90%;
  }
  /* line 65, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent h1 {
    font-size: 1.2rem;
    text-align-last: left;
  }
  /* line 68, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent .AwardText {
    padding-bottom: 0px;
  }
  /* line 70, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent .AwardText p {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  /* line 73, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent .SliderBoxheight {
    width: 320px;
    height: 240px;
  }
  /* line 76, ../sass/page/_aboutUsPage.sass */
  .AboutUsContent .SliderBoxheight img {
    height: 240px;
  }
}
/* line 8, ../sass/page/_infoPages.scss */
#page-aboutUs .content,
#page-memberInfo .content,
#page-memberNotice .content,
#page-memberQA .content,
#page-redeemInfo .content,
#page-shoppingInfo .content,
#page-shoppingNotes .content {
  color: #696969;
  padding-bottom: 120px;
  width: 60%;
  margin: auto;
  line-height: 1.85rem;
  min-height: 600px;
}
/* line 15, ../sass/page/_infoPages.scss */
#page-aboutUs .content ul, #page-aboutUs .content li,
#page-memberInfo .content ul,
#page-memberInfo .content li,
#page-memberNotice .content ul,
#page-memberNotice .content li,
#page-memberQA .content ul,
#page-memberQA .content li,
#page-redeemInfo .content ul,
#page-redeemInfo .content li,
#page-shoppingInfo .content ul,
#page-shoppingInfo .content li,
#page-shoppingNotes .content ul,
#page-shoppingNotes .content li {
  padding: 0;
  margin: 0;
}
/* line 19, ../sass/page/_infoPages.scss */
#page-aboutUs .content ul,
#page-memberInfo .content ul,
#page-memberNotice .content ul,
#page-memberQA .content ul,
#page-redeemInfo .content ul,
#page-shoppingInfo .content ul,
#page-shoppingNotes .content ul {
  padding: 10px 0;
}
/* line 21, ../sass/page/_infoPages.scss */
#page-aboutUs .content ul li,
#page-memberInfo .content ul li,
#page-memberNotice .content ul li,
#page-memberQA .content ul li,
#page-redeemInfo .content ul li,
#page-shoppingInfo .content ul li,
#page-shoppingNotes .content ul li {
  padding-left: 2em;
  text-indent: -2em;
}
/* line 27, ../sass/page/_infoPages.scss */
#page-aboutUs .content ul.sub li,
#page-memberInfo .content ul.sub li,
#page-memberNotice .content ul.sub li,
#page-memberQA .content ul.sub li,
#page-redeemInfo .content ul.sub li,
#page-shoppingInfo .content ul.sub li,
#page-shoppingNotes .content ul.sub li {
  padding-left: 1.1em;
  text-indent: -1.1em;
}
/* line 32, ../sass/page/_infoPages.scss */
#page-aboutUs .content img,
#page-memberInfo .content img,
#page-memberNotice .content img,
#page-memberQA .content img,
#page-redeemInfo .content img,
#page-shoppingInfo .content img,
#page-shoppingNotes .content img {
  width: 100%;
  margin-bottom: 30px;
}
/* line 36, ../sass/page/_infoPages.scss */
#page-aboutUs .content .title,
#page-memberInfo .content .title,
#page-memberNotice .content .title,
#page-memberQA .content .title,
#page-redeemInfo .content .title,
#page-shoppingInfo .content .title,
#page-shoppingNotes .content .title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 30px;
  padding-top: 30px;
}

/* line 49, ../sass/page/_infoPages.scss */
#page-memberQA .content ul > li,
#page-shoppingNotes .content ul > li {
  margin-bottom: 30px;
}
/* line 51, ../sass/page/_infoPages.scss */
#page-memberQA .content ul > li > div,
#page-shoppingNotes .content ul > li > div {
  padding-left: 1em;
  text-indent: -1.8em;
}
/* line 54, ../sass/page/_infoPages.scss */
#page-memberQA .content ul > li > div:first-child,
#page-shoppingNotes .content ul > li > div:first-child {
  font-weight: bold;
}
/* line 57, ../sass/page/_infoPages.scss */
#page-memberQA .content ul > li > div:last-child,
#page-shoppingNotes .content ul > li > div:last-child {
  color: #898989;
}

@media only screen and (max-width: 768px) {
  /* line 76, ../sass/page/_infoPages.scss */
  #page-aboutUs .TitleBox,
  #page-memberInfo .TitleBox,
  #page-memberNotice .TitleBox,
  #page-memberQA .TitleBox,
  #page-redeemInfo .TitleBox,
  #page-shoppingInfo .TitleBox,
  #page-shoppingNotes .TitleBox,
  #page-redeems .TitleBox,
  #page-story .TitleBox,
  #page-news .TitleBox {
    width: 100%;
  }
  /* line 78, ../sass/page/_infoPages.scss */
  #page-aboutUs .TitleBox .Title,
  #page-memberInfo .TitleBox .Title,
  #page-memberNotice .TitleBox .Title,
  #page-memberQA .TitleBox .Title,
  #page-redeemInfo .TitleBox .Title,
  #page-shoppingInfo .TitleBox .Title,
  #page-shoppingNotes .TitleBox .Title,
  #page-redeems .TitleBox .Title,
  #page-story .TitleBox .Title,
  #page-news .TitleBox .Title {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 0;
  }
  /* line 83, ../sass/page/_infoPages.scss */
  #page-aboutUs .TitleBox .Title span,
  #page-memberInfo .TitleBox .Title span,
  #page-memberNotice .TitleBox .Title span,
  #page-memberQA .TitleBox .Title span,
  #page-redeemInfo .TitleBox .Title span,
  #page-shoppingInfo .TitleBox .Title span,
  #page-shoppingNotes .TitleBox .Title span,
  #page-redeems .TitleBox .Title span,
  #page-story .TitleBox .Title span,
  #page-news .TitleBox .Title span {
    width: 50%;
    margin-bottom: 10px;
    text-align: center;
  }
  /* line 90, ../sass/page/_infoPages.scss */
  #page-aboutUs .content,
  #page-memberInfo .content,
  #page-memberNotice .content,
  #page-memberQA .content,
  #page-redeemInfo .content,
  #page-shoppingInfo .content,
  #page-shoppingNotes .content,
  #page-redeems .content,
  #page-story .content,
  #page-news .content {
    width: 90%;
  }
}
/* line 1, ../sass/page/_englishPage.sass */
.english {
  width: 90%;
  margin: 80px auto;
}

/* line 5, ../sass/page/_englishPage.sass */
.englishItem {
  padding-bottom: 3rem;
  margin-bottom: 1.5rem;
  border-bottom: 3px double #cf1227;
}

/* line 10, ../sass/page/_englishPage.sass */
.englishImage {
  width: 100%;
}
